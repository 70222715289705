.section3{
    width: 70%;
    margin: auto;
    height: 75vh;
    margin-top: -100px;
    position: relative;
}

.section-text h1{
    font-size: 52px;
    font-weight: 700;
    font-family: Anek Devanagari;
    line-height: 60px;
    width: 514px;
    height: 240px;
}
.section-text p{
    font-size: 16px;
    font-weight: 300;
    font-family: Anek Devanagari;
    line-height: 27.29px;
    width: 464px;
    height: 108px;
}
.section-text button{
    width: 125px;
    height: 37px;
    background-color: var(--main-color);
    border: none;
    color: #fff;
    outline: none;
    border-radius: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    position: absolute;
  left: 32%;
}
.section3 .section-card{
    width: 230px;
    height: 361px;
    border-radius: 20px;
    box-shadow: -5px 4px 25px 0 rgba(0, 0, 0, 0.25);
    padding: 25px 0;
   position: relative;
   left: 200px;
   background-color: #fff;
  
}
.section3 .section-card h2{
    font-size: 20px;
    font-weight: 500;
    font-family: Anek Devanagari;
    line-height: 34.11px;
  margin: 0px 0 0 25px;
}

.section3 .earth{
    position: absolute;

    bottom: 34%;
 left: 74%;
 z-index: -1000;
}
.section3 .chart{
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    position: relative;
    left: 400px;
    top: -130px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    overflow-y: hidden;
}
.section3 .chart span{
    background-color: rgba(71, 128, 255, 1);
    display: block;
    width: 5px;
    position: relative;
    top: 20px;
   
}
.section3 .chart span:nth-child(1){
    height: 33px;
}
.section3 .chart span:nth-child(2){
    height: 23px;
}
.section3 .chart span:nth-child(3){
    height: 43px;
}
/* media screen */

@media screen and (max-width: 768px){
    .section3{
        width: 80%;
        margin: auto;
        height: 42vh;
        margin-top: 0px;
        position: relative;
        top: 800px;
    }
    .section-text h1{
        font-size: 32px;
        line-height: 30px;
        width: 300px;
        position: relative;
        top: 120px;
    }
    .section-text p{
        font-size: 14px;
        line-height: 20px;
        width: 300px;
    }
    .section3 .earth{
        position: absolute;
    width: 200px;
        bottom: 0%;
     left: 40%;
     z-index: -1000;
    }
    .section-text button{
        width: 125px;
        height: 37px;
        background-color: var(--main-color);
        border: none;
        color: #fff;
        outline: none;
        border-radius: 50px;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        position: absolute;
      left: 25%;
      top: 320px;
    }
    .section3 .section-card{
        left: 0;
    }
    .section3 .chart {
        left: 190px;
    }
}