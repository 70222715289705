.about-section{
    width: 70%;
    margin: auto;
    height: 50vh;
    margin-bottom: 300px;

}
.about-text h1{
    width: 493px;
    height: 120px;
    font-weight: 700;
    font-size: 52px;
    line-height: 60px;
    font-family: Anek Devanagari;
}
.about-text p{
    width: 464px;
    height: 81px;
    font-weight: 300;
    font-size: 16px;
    line-height: 27.29px;
    font-family: Anek Devanagari;
    margin-left: 50px;
}
.about-text button{
    width: 125px;
    height: 37px;
    background-color: var(--main-color);
    border: none;
    color: #fff;
    outline: none;
    border-radius: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    position: relative;
    left: 325px;
}
.about-section .images{
    position: relative;
}
.about-section .images::before{
position: absolute;
    content: "";
    width: 217px;
    height: 217px;
    border-radius: 50%;
    background-color: rgba(210, 212, 255, 1);
    left: 95px;
    top: -15px;
    z-index: -1;
}
.about-section .images::after{
    position: absolute;
        content: "";
       width: 233px;
       height: 102px;
       border-radius: 0px 0px 15px 200px;
       background-color:rgba(79, 141, 138, 1);
       top: 225px;
       left: 80px;
    }
    .about-section .social-images{
        width: 450px;
        position: relative;
        top: 120px;
    }
    .about-section .social-images::after{
        width: 233px;
        height: 387px;
        position: absolute;
        content: "";
        /* border-radius:71% 29% 66% 34% / 72% 28% 72% 28% ; */
        border-radius: 550px;
        transform: rotate(-43.59deg);
        background-color: rgba(248, 248, 248, 1);
        left:70px;
        top: -320px;
        z-index: -555;

    }
    .about-section .social-images .facebook{
        position: relative;
        top: -470px;
        left: 200px;
    }
    .about-section .social-images .instagram{
        position: relative;
        left: 220px;
        top: -230px;
    }
    .about-section .social-images .youtube{
        position: relative;
        left: -250px;
        top: -270px;
    }
    .about-section .social-images .website{
        position: relative;
        top: -90px;
        left: 200px;
    }
    .about-section .social-images .snapchat{
        position: relative;
        top: -70px;
        left: -140px;
    }
    .about-section .social-images .tik-tok{
        position: relative;
        left: -70px;
        top: -390px;
    }
    .about-section .social-images #x-twitter{
        position: relative;
        top: -450px;
        left: -200px;
    }

    