/* mylinks page area */
/* Sidebar area  */
/* DomainManagement page area  */
/* subscription page area  */
/* Remove Brand page area */
/* My Account Page area  */
/* Insight Page area  */
/* overview area */
/* Source area */
/* Devices area  */
/* Systems area  */
/* Links area  */
/* Customize Page area  */
/* Appearance */
@import url("https://fonts.googleapis.com/css2?family=Anek+Devanagari:wght@100..800&family=Inter:wght@100..900&display=swap");
:root {
  --blue-color-200: #19609c;
  --blue-color-100: #c3d6e5;
  --body-color: rgb(255, 255, 255);
  --gray-color-200: #b4b4b4;
  --gray-color-100: #fafafa;
  --gradient-color: linear-gradient(180deg, #19609c, #54b99b);
  --black-color: black;
  --red-color: #d24949;
  --green-color-200: #91d6c2;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-weight: normal;
}
html {
  height: 100%;
}
body {
  height: 100%;
}
button {
  border: none;
  outline: none;
  background: var(--body-color);
  cursor: pointer;
}

/* ==========================Sidebar area ====================== */

.nav-home {
  /* height: 56px; */
  height: 80px;
  width: 100%;
  /* top: 0%; */
  /* position: fixed; */
  /* position: fixed; */
  /* background: var(--gradient-color); */
  background: #f5f7fc;
  /* z-index: 4; */
}
.title {
  position: relative;
  top: -66px;
  left: 40%;
  /* transform: translateX(-50%); */
  /* position: fixed;
  top: 0;
  left: 50%; */
  color: var(--body-color);
  font-size: 2rem;
  z-index: 10;
  /* display: flex; */
  display: none;
  align-items: center;
  gap: 50px;
}
.container {
  display: flex;
  /* position: fixed; */
  /* overflow: hidden; */
}
.cont {
  /* position: relative; */
  /* top: 56px; */
  /* top: 80px; */
  position: relative;
  /* top: 56px; */
  /* top: 80px; */
  height: 100vh;
  background: #f5f7fc;
}
main {
  width: 100%;

  padding-bottom: 24px;
  /* left: 14rem; */
  margin-left: 0rem;
  position: relative;
  /* height: 93vh; */
  left: 150px;
  z-index: 15;
  background-color: rgba(245, 247, 252, 1);
}
.sidebar {
  background: var(--body-color);
  color: var(--gradient-color);
  /* height: 92vh; */
  height: 100vh;
  top: 0;
  padding-top: 75px;
  width: 200px;
  transition: all 0.5s;
  /* box-shadow: 3px 4px 5px var(--gray-color-200); */
  border-radius: 10px;
  position: fixed;
  /* margin-right: 7rem; */
  /* left: 23px; */
  left: 0px;
  z-index: 60;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.link {
  display: flex;
  color: var(--gray-color-200);
  fill: var(--gray-color-200);
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}
.link:hover {
  color: var(--blue-color-200);
  fill: var(--blue-color-200);
  transition: all 0.5s;
}
.active {
  color: var(--blue-color-200);
  fill: var(--blue-color-200);
  background-color: var(--blue-color-100);
}
.icon,
.link_text {
  font-size: 20px;
}

.account-links {
  margin-top: 5rem;
}

.account-links hr {
  color: var(--gray-color-100);
  width: 90%;
  text-align: center;
  position: relative;
  left: 4%;
}
.main-cont {
  position: relative;
  /* width: 95%; */
  /* height: 233px; */
  border-radius: 5px;
  background-color: #fff;
  /* temp  */
  margin: auto;
  /* max-width: 1200px; */
  /* margin-left: 50px; */
  /* left: -15px; */
  left: -50px;
}

/* .link.active::before {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  border-left: 4px solid #38e199;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
} */

/* ============================mylinks ================================ */
.my-links-brief {
  height: 4rem;
  width: 95%;
  margin: auto;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding-bottom: 7px;
  border-bottom: solid 1px #555;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.left-column,
.right-column,
.center-column {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
}
.left-column {
}
.sub-column {
  box-sizing: border-box;
}

.option-list {
  /* background :red ; */
}

.option-list {
  border-radius: 5px !important;
  height: 2.3rem;
  margin-right: 7.5rem !important;
}

.left-column {
  margin-bottom: 3rem !important;
  position: absolute;
  right: -8%;
  top: 4px;
  justify-content: center;
  align-content: center;
  font-family: Anek Devanagari;
}
.text1 {
  color: rgba(0, 0, 0, 1);
  margin-right: 2rem;
  margin-left: 2rem;
  font-size: 18px;
  font-weight: 300;
  line-height: 60px;
  font-family: Anek Devanagari;
}

.links-count {
  background-color: rgba(3, 127, 154, 1);
  color: var(--body-color);
  width: 28px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  height: 16.5px;
  position: relative;
  left: -20px;
  top: 20px;
  line-height: 15px;
}

.sub-column.dropdown {
  color: var(--blue-color-200);
  border-radius: 10px;
  text-align: center;
  padding: 3px;
  cursor: pointer;
  margin-right: 5rem;
  position: relative;
  width: 11rem;
}
.dropdown-content {
  background-color: var(--body-color);
  background-color: var(--body-color);
  position: absolute;
  right: 12px;
}
.drop-option {
  background-color: var(--body-color);
  columns: var(--black-color);
  margin: 1rem 0rem;
  list-style: none;
  width: 7.5rem;
  height: 3rem;
  text-align: center;
  justify-content: center;
  display: flex;
}
.drop-option:hover {
  color: var(--blue-color-200);
  background-color: var(--gray-color-100);
}

.sub-column.dropdown::after {
  content: "";
  border: 4px solid transparent;
  border-left: 4px solid #000; /* You can adjust the color */
  position: absolute;
  top: 66%;
  left: 80%;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
  transform: translateY(-50%) rotate(90deg) !important;
}
.sub-column.dropdown.open::after {
  transform: translateY(-50%) rotate(90deg);
}
button.sub-column {
  color: var(--body-color);
  right: 104px;
  /* top: -5.3%; */
  top: 1px;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  width: 93px;
  font-size: 16px;
  padding: -12px 10px;
  position: relative;
  height: 35px;
  text-decoration: none;
  background-color: rgba(3, 127, 154, 1);
}
button.sub-column:hover {
  box-shadow: 0 4px 50px 5px rgba(3, 127, 154, 1);
}

.delete-box-cont {
  position: relative;
}

/* ==================my links card========================= */
.link-card {
  margin-top: 0rem;
  background: var(--body-color);
  height: 10rem;
  padding: 0px 13px 2px 25px;
  width: 100%;
  display: flex;
}

.link-card,
.first-div-card,
.second-div-card,
.third-div-card {
  display: flex;
  justify-content: space-between;
}
.first-div-card img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-right: 2rem;
}
.first-div-card p {
  font-family: Anek Devanagari;
  font-size: 14px;
  font-weight: 200;
}

.first-div-card,
.third-div-card {
  justify-content: center;
  align-items: center;
  align-content: center;
}
.third-div-card {
  flex-wrap: wrap;
}
.second-div-card {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: 15px;
}

.icon-card {
  background: var(--body-color);
  border-radius: 50%;
  height: 50px;
  margin: 1rem;
  width: 50px;
  text-align: center;
  /* border: solid 2px rgba(3, 127, 154, 1); */
  color: var(--gray-color-200);
  fill: var(--gray-color-200);
  font-size: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.icon-card:hover {
  background-color: red;
}
.icon-card svg {
  width: 32px;
  height: 32px;
}
.icon-card svg:hover {
  color: #fff;
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-weight: normal;
}
.icon-card:hover {
  background: var(--blue-color-200);
  color: var(--body-color);
  fill: var(--body-color);
  cursor: pointer;
}

/* ====================my links popups================== */

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.11);
  z-index: 9999;
  cursor: pointer;
}

.ShareLinkCont {
  background: var(--body-color);

  position: relative;
  top: 17%;
  width: 32rem;
  height: fit-content;
  left: 36%;
  border-radius: 13px;
  padding: 2rem 1rem;
}
.sharelink-title {
  font-weight: bold;
}
.cross-btn {
  position: absolute;
  right: 3%;
  top: 4%;
  font-size: 27px;
  font-weight: bold;
}

.sharelink-des {
  padding-top: 4px;
  color: #666;
  margin-bottom: 20px;
}
.soc-title {
  margin-bottom: 3rem;
}

.shr-title {
  position: relative;
  top: -2.5rem;
  left: 8rem;
  font-size: 2rem;
}
.shr-title-qr {
  position: relative;
  top: -2.5rem;
  font-size: 2rem;
}
.sharelink-social,
.sharelink-qr {
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
}

.arrow-link {
  position: absolute;
  right: 3%;
  font-size: 27px;
  font-weight: bold;
}

.copy-link-box {
  height: 3rem;
  width: 96%;
  background-color: white;
  box-shadow: 1px 2px 8px 0px #c6c4c4;
  display: flex;
  justify-content: space-between;
  padding: 7px;
  border-radius: 13px;
  align-content: center;
  justify-items: center;
  align-items: center;
  flex-direction: row;
}

.copy-link-button {
  background: var(--gradient-color);
  color: var(--body-color);
  right: -0.5rem;
  top: -1.3%;
  color: #fff;
  border-radius: 13px;
  text-align: center;
  cursor: pointer;
  width: 9rem;
  box-shadow: 2px 5px 8px 0px #c6c4c4;
  display: flex;
  flex-direction: row;
  padding: 5px 30px;
  position: relative;
  height: 3.9rem;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.copy-link-logo {
  width: 5rem;
  position: relative;
  top: 2px;
}

.download-qr-button {
  background: var(--gradient-color);
  color: var(--body-color);
  right: -0.5rem;
  top: -1.3%;
  color: #fff;
  border-radius: 13px;
  text-align: center;
  cursor: pointer;
  width: 20rem;
  box-shadow: 2px 5px 8px 0px #c6c4c4;
  display: flex;
  flex-direction: row;
  padding: 5px 30px;
  position: relative;
  height: 3.9rem;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.qr-con,
.qr-cont2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  align-items: center;
  gap: 2rem;
}

.share-icons {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  left: 2rem;
  position: relative;
}

.shr-soc-cont {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.shr-soc-cont:hover {
  /* color: var(--blue-color-200); */
  transition: all 0.5s ease;
  background-color: #738ea9;
}

.shr-soc-icon {
  width: 2.5rem;
}

.form-custom {
  top: 34%;
  left: 35%;
}
.delete-box {
  background: var(--body-color);
  width: 500px;
  height: 285px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
}

.icon-excl {
  font-size: 142px !important;
  display: block;
  text-align: center;
  left: 46%;
  /* position: fixed; */
  font-style: normal;
  border-radius: 7px;
  margin-left: 35%;
}

.ok-btn {
  background: var(--red-color);
  color: var(--body-color);
}
/* =========================DomainManagement page area===================== */

.domain-content {
  margin-left: 16%;
  height: 58%;
  width: 508px;
  color: var(--body-color);
  box-shadow: 0px 2px 5px 1px var(--gray-color-200);
  top: -6.5rem;
  position: relative;
  border-radius: 5%;
  left: 12rem;
  padding: 2rem 0rem 3rem 0rem;
}
.settingbar-cont .active {
  width: 15rem;
  background-color: transparent;
}
.settingbar-cont .link {
  width: 15rem;
}

.domain-content img {
  height: 255.79px;
  width: 344px;
  justify-content: center;
  display: flex;
  align-content: center;
  position: relative;
  left: 15%;
  top: 4%;
}
.domain-content .first-p {
  color: var(--black-color);
  position: relative;
  left: 15%;
  top: 69%;
}
.domain-content .second-p {
  color: var(--black-color);
  position: relative;
  left: 9%;
  top: 75%;
}
/* .settingbar-cont .link{
  color: var(--black-color);

} */

/* =========================Subscription page area===================== */

.subscription-content {
  margin-left: 16%;
  position: relative;
  top: -6.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 580px;
  gap: 2rem;
  left: 8rem;
}
.subscription-1st-card {
  width: 33rem;
  box-shadow: 0px 2px 5px 1px var(--gray-color-200);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 11px;
}
.subscription-content .card .header,
.subscription-content .header {
  margin-bottom: 34px;
}
.subscription-content .card .body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
.subscription-3rd-card .body {
  gap: 2.5rem;
  margin-left: 1rem;
}

.subscription-2nd-card {
  width: 33rem;
  box-shadow: 0px 2px 5px 1px var(--gray-color-200);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 11px;
}
.subscription-content .subscription-2nd-card .body {
  gap: 28px;
  justify-content: center;
  margin-bottom: 2rem;
}
.subscription-3rd-card {
  width: 33rem;
  box-shadow: 0px 2px 5px 1px var(--gray-color-200);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 11px;
}
/* =========================Remove Brand page area===================== */

.remove-brand-content {
  margin-left: 16%;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  width: 33rem;
  box-shadow: 0px 2px 5px 1px var(--gray-color-200);
  padding: 3px;
  padding-right: 3px;
  padding-right: 3px;
  padding-right: 33px;
  border-radius: 12px;
  position: absolute;
  top: -5.5rem;
  left: 12rem;
}
.remove-brand-content p {
  flex: 1;
}
.switch-btn {
  display: inline-block;
  width: 46px;
  height: 20px;
  vertical-align: middle;
  border-radius: 12px;
  background: #8b8584;
  position: relative;
  cursor: pointer;
}

.switch-btn::before {
  content: " ";
  display: inline-block;
  width: 18px;
  height: 17px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 1px;
  top: 1px;
}

.switch-btn::before {
  content: " ";
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 1px;
  top: 1px;
  transition: left 0.3s ease-in-out;
}

.switch-btn.open {
  background: #4caf50;
}

.switch-btn.open::before {
  left: 27px;
}

/* ==========================My Account Page area====================== */

.myAccountCont {
  width: 49%;
  left: 23%;
  position: relative;
  /* padding: 10px; */
  margin-bottom: 10rem;
  box-shadow: 1px 8px 8px 0px #c6c4c4;
}
.account-form-row1 input {
  display: block;
  width: 100%;
  margin: 2rem 0rem;
  height: 2rem;
  padding: 8px;
  position: relative;
}

.VC-cont {
  position: relative;
}
.account-form-row1 .send {
  text-decoration: none;
  position: absolute;
  right: 10px;
  bottom: 40%;
  color: var(--blue-color-200);
  cursor: pointer;
  background: #fff;
}

.account-form-row1 .cancel,
.account-form-row1 .bind {
  border: var(--gray-color-200) solid 1px;
  margin: 2rem;
  height: 3rem;
  width: 7rem;
  padding: 3px;
  position: relative;
  left: 44%;
  border-radius: 5px;
  box-shadow: 0px 4px 10px -2px var(--gray-color-200);
}

.account-form-row1 .bind {
  color: var(--body-color);
  background: var(--gradient-color);
}
.account-form-row3 input {
  display: block;
  width: 100%;
  margin: 2rem 0rem;
  height: 2rem;
  padding: 8px;
  position: relative;
}

.account-form-row3 .cancel,
.account-form-row3 .save {
  border: var(--gray-color-200) solid 1px;
  margin: 2rem;
  height: 3rem;
  width: 7rem;
  padding: 3px;
  position: relative;
  left: 44%;
  border-radius: 5px;
  box-shadow: 0px 4px 10px -2px var(--gray-color-200);
}

.account-form-row3 .save {
  color: var(--body-color);
  background: var(--gradient-color);
}

.delete {
  color: var(--body-color);
  background: var(--red-color);
  border: var(--gray-color-200) solid 1px;
  margin: 2rem;
  height: 3rem;
  width: 10rem;
  padding: 3px;
  position: relative;
  left: 68%;
  border-radius: 5px;
}

.table-container input {
  border-radius: 13px;
  box-shadow: 0px 4px 10px -2px var(--gray-color-200);
}

.profile-row {
  display: flex !important;
  justify-content: flex-start;
  justify-items: center;
  align-content: center;
  align-items: center;
  gap: 20px;
}

.login-type {
  color: var(--gray-color-200);
}
.profile-label {
  cursor: pointer;
}
/* .css-1ex1afd-MuiTableCell-root:last-child{

  border: none;
} */

/* =====================insight page area====================== */

.insight-content {
  margin-left: 16%;
  height: 58%;
  width: 596px;
  color: var(--body-color);
  box-shadow: 0px 2px 5px 1px var(--gray-color-200);
  top: -20.5rem;
  position: relative;
  border-radius: 5px;
  /* left: 9rem; */
  color: var(--black-color);
}
.insight-content h1 {
  padding: 10px 15px;
}
.Insightbar-cont .active {
  background-color: transparent;
}
/* =====================overview area================================ */
.chart-title-cont {
  display: flex;
  justify-content: space-between;
  padding: 4rem;
}

.chart-info {
  color: black;
  margin-left: 4rem;
  margin-bottom: 3rem;
  cursor: pointer;
  display: inline-block;
}
.chart-title {
  color: black;
  left: 4rem;
  bottom: 5rem;
  display: inline-block;
}

.chart-info span {
  position: relative;
  bottom: 24%;
}

.chart-info-breif {
  color: black;
  position: absolute;
  background: var(--body-color);
  width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  top: 20%;
  left: 32%;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.info-hr {
  width: 31rem;
  color: var(--body-color);
}

.info-data {
  display: flex;
  justify-content: center;
  margin: 11px 5px;
  border: 1px solid var(--gray-color-200);
  padding: 10px;
}

.report-card {
  color: var(--black-color);
  margin-left: 7%;
  height: 8rem;
  width: 508px;
  box-shadow: 0px 2px 5px 1px var(--gray-color-200);
  position: relative;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}

/* ===========================Source area====================== */
.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  left: 0rem !important;
  top: 217px !important;
  right: 6px !important;
  display: flex !important;
  flex-direction: row !important;
  margin-top: 6rem !important;
  position: absolute !important;
  height: 4rem;
}
foreignObject {
  width: 63rem !important;
  height: 28rem !important;
}

#SvgjsSvg1066 {
  height: 28rem;
}

.apexcharts-legend-marker {
  width: 40px !important;
  height: 20px !important;
  border-radius: 3px !important;
  top: 4px !important;
}
.container-fluid.pie-chart-cont {
  margin-left: 7rem;
}
.container-fluid.pie-chart-cont h1 {
  color: var(--black-color);
  position: absolute;
  /* left: 18px;
  margin: 1rem; */

  left: -7px;
}

/* sources table  */

.sources-table {
  width: 90%;
  margin: 2rem;
  color: #000;
}

.sources-table thead {
  display: flex;
  gap: 51%;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.piechart-sources-icon {
  position: absolute;
}
.sources-table tr {
  display: flex;
  gap: 7%;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
.sources-table tbody td {
  margin: 4rem 5.3rem;
  display: inline-block;
}
.sources-table tbody tr :first-child {
  margin: 0rem;
  display: inline-flex;
  width: 5rem;
}

.apexcharts-svg {
  height: 500px !important;
}

/* ==============================Devices area====================== */

/* devices table  */

.devices-table {
  width: 90%;
  margin: 2rem;
  color: #000;
}

.devices-table thead {
  display: flex;
  gap: 51%;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.piechart-devices-icon {
  position: absolute;
}
.devices-table tr {
  display: flex;
  gap: 7%;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
.devices-table tbody td {
  margin: 4rem 5.3rem;
  display: inline-block;
}
.devices-table tbody tr :first-child {
  margin: 0rem;
  display: inline-flex;
  width: 5rem;
}

.apexcharts-svg {
  height: 500px !important;
}

/* ==============================systems area====================== */

/* systems table  */

.systems-table {
  width: 90%;
  margin: 2rem;
  color: #000;
}

.systems-table thead {
  display: flex;
  gap: 51%;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.piechart-systems-icon {
  position: absolute;
}
.systems-table tr {
  display: flex;
  gap: 7%;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
.systems-table tbody td {
  margin: 4rem 5.3rem;
  display: inline-block;
}
.systems-table tbody tr :first-child {
  margin: 0rem;
  display: inline-flex;
  width: 5rem;
}

.apexcharts-svg {
  height: 500px !important;
}
.systems-table img {
  position: relative;
  bottom: 15px;
}

/* ==================Links Area================== */
.links-chart {
  height: 16rem;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: var(--gray-color-200);
}

/* links table  */

.links-table {
  width: 90%;
  margin: 2rem;
  color: #000;
}

.links-table thead {
  display: flex;
  gap: 51%;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.piechart-links-icon {
  position: absolute;
}
.links-table tr {
  display: flex;
  gap: 7%;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
.links-table tbody td {
  margin: 4rem 5.3rem;
  display: inline-block;
}
.links-table tbody tr :first-child {
  margin: 0rem;
  display: inline-flex;
  width: 5rem;
}

.apexcharts-svg {
  height: 500px !important;
}
.links-table img {
  position: relative;
  bottom: 15px;
}

/* =========================Customize Page area ============================ */

.customize-cont {
  display: flex;
  /* gap: 20px; */
  justify-content: space-between;
}
.yousef {
  background-color: rgba(245, 247, 252, 1);
}

.control-section {
  flex: 2.2 1;
  /* ::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 100%;
  } */
}
.preview-section {
  flex: 1.5 1;
  position: relative;
  left: -45px;
}

.copy-button-container {
  width: 6rem;
  box-shadow: 0px 3px 4px 1px var(--gray-color-200);
  /* height: 3rem; */
  text-align: center;
  justify-content: center;
  display: flex;
  border-radius: 1rem;
}
.appearance-cont,
.my-page-cont {
  border-radius: 3px;
  padding: 15px 25px 15px 25px;
  width: 594px;
  /* height: 451px; */
}

/* =========================appearance themes buttons======================= */

.firstTheme {
  background: url("../src/assets/Images/AppearanceThems/1stTheme.PNG");
  background-size: cover;
}
.secondTheme {
  background: url("../src/assets/Images/AppearanceThems/2ndTheme.PNG");
  background-size: cover;
}

.thirdTheme {
  background: url("../src/assets/Images/AppearanceThems/4thTheme.PNG");
  background-size: cover;
}

.forthTheme {
  background: url("../src/assets/Images/AppearanceThems/5thTheme.PNG");
  background-size: cover;
}
.fifthTheme {
  background: url("../src/assets/Images/AppearanceThems/3rdTheme.PNG");
  background-size: cover;
}

.sixTheme {
  background: url("../src/assets/Images/AppearanceThems/6thTheme.PNG");
  background-size: cover;
}
.preview-cont {
  color: var(--gradient-color);
  height: 100vh;
  width: 31%;
  transition: all 0.5s;
  box-shadow: -3px 4px 5px var(--gray-color-200);
  border-radius: 10px;
  position: fixed;
  margin-right: 0rem;
  padding: 1rem 2rem;
}

.body-preview {
  margin-top: 2rem;
}

.mobile-cont {
  background: var(--body-color);
  box-shadow: 1px 1px 7px 2px #c6c4c4;
  height: 31rem;
  border: var(--gray-color-200) 9px solid;
  border-radius: 20px;
  width: 67%;
  margin: auto 4rem;
  overflow: scroll;
  overflow-x: hidden;
}

.online-store-btn {
  display: block;
  padding: 1rem 2rem;
  top: 13rem;
  border: solid 2px var(--gray-color-200);
  position: relative;
  left: 18%;
}

.btn-border-radius {
  background: #060606;
  width: 6rem;
  height: 4rem;
  margin: 1rem;
}

.border-control {
  display: flex;
  gap: 2rem;
}

.MuiInput-input {
  width: 4rem;
  height: 2rem;
  text-align: center;
}

.second-btn {
  background: white;
  box-shadow: #000 6px 6px 0 0;
  border: 2px solid;
}
.third-btn {
  background: white;
  box-shadow: #000 1px 4px 0 0;
  border: 2px solid;
}
.forth-btn {
  background: #000;
  box-shadow: #eaeaea 0px 1px 0px 11px;
  border: 2px solid;
  padding: 10px;
}
.fifth-btn {
  background: white;
  border: 2px dashed;
  padding: 10px;
}

.six-btn {
  background: #060606;
  width: 6rem;
  height: 4rem;
  margin: 1rem;
  position: relative;
}
/* 
.six-btn::before {
  position: absolute;
  content: '';
  top: 64px;
  height: 8px;
  width: 100%;
  background-size: 12px 12px;
  background-position: -5px -3px;
  background-image: radial-gradient(circle at 50% 0%, black 50%, transparent 55%);
  left: 0rem;
  
}




.six-btn::after {
  position: absolute;
  content: '';
  top: -8px;
  height: 8px;
  width: 100%;
  background-size: 12px 12px;
  background-position: -5px -3px;
  background-image: radial-gradient(circle at 50% 0%, black 50%, transparent 55%);
  transform: rotate(180deg);
  left: 0rem;
} */

.seven-btn {
  box-shadow: -5px -4px 0 4px rgba(0, 255, 255, 0.5),
    -5px 4px 0 4px rgba(255, 0, 0, 0.5), 4px 5px 0 4px rgba(0, 255, 0, 0.5),
    4px -4px 0 4px rgba(0, 0, 255, 0.5);
}

.last-btn {
  box-shadow: -4px -4px 1px 4px rgba(0, 255, 243, 0),
    -4px 4px 4px 4px rgb(244, 4, 4), 4px 4px 4px 4px rgba(128, 0, 255, 0),
    4px -4px 4px 4px rgb(0, 254, 254), 0px -12px 0px -4px rgba(125, 255, 0, 0.5);
}

/* *****************************new try***********************

.theme-container{

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
} */

/* ==================appearance===================== */
.appearance-header {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  align-items: center;
}
.apply-btn {
  background: var(--gradient-color);
  color: var(--body-color);
  width: 7rem;
  padding: 3%;
  font-weight: bolder;
  border-radius: 16px;
}

a.more-tmp-link {
  width: 9.5rem;
  display: block;
  height: 7rem;
  border: 4px dashed var(--gray-color-200);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 2rem 3rem 2rem;
}

.div-fonts {
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.font-type {
  font-size: 20px;
  margin-right: 2%;
  cursor: pointer;
}
.font-type:hover {
  font-size: 22px;
  color: var(--blue-color-200);
}

.font-type-header {
  margin: 3rem 0 2rem 0rem;
}

span.texxt-color-sw {
  width: 26px;
  height: 26px;
  display: block;
  /* border: 4px solid var(--gray-color-200); */
  border-radius: 2rem;
  margin: 7px 0px 6px 10px;
  cursor: pointer;
}

.font-type-cir-cont {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0rem;
  width: 22rem;
}

span.gradient-color-sw {
  width: 80px;
  height: 73px;
  display: block;
  border: 4px solid var(--gray-color-200);
  border-radius: 1rem;
  margin: 7px 0px 6px 12px;
  cursor: pointer;
}

.gradient-cir-cont {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0rem;
  width: 98%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* ************************profile preview********************************** */
.prev-header-container.mt-5 {
  display: flex;
  justify-content: flex-start;
  justify-items: center;
  align-content: center;
  align-items: center;
  gap: 1rem;
}

.profile-img-prev {
  /* width: 45% !important; */
}
/* *************************profile block*********************************** */

.img-profile-con {
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: flex-start;
}
.img-profile-con img {
  border: 3px solid var(--gray-color-200);
  border-radius: 5px;
  margin: 0.3rem;
  cursor: pointer;
  width: 10rem;
}
.title-info {
  font-family: Anek Devanagari !important;
  font-size: 18px !important;
  /* font-weight: 300 !important; */
  line-height: 40px !important;
  color: #000 !important;
  margin-left: 15px;
}

input[type="range"] {
}
/* =========================Template1===================== */
.template1 {
  background-image: url("assets/Image/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #33f8fe;
}

::-webkit-scrollbar {
  display: none;
}

.template1 .container,
.template2 .container,
.template3 .container,
.template4 .container,
.template5 .container,
.template6 .container,
.template7 .container,
.template8 .container,
.template9 .container,
.template10 .container,
.template11 .container,
.template12 .container,
.template13 .container,
.template14 .container,
.template15 .container,
.template16 .container,
.template17 .container,
.template18 .container,
.template19 .container,
.template20 .container,
.template21 .container,
.template22 .container,
.template23 .container,
.template24 .container,
.template25 .container,
.template26 .container,
.template27 .container,
.template28 .container,
.template29 .container,
.template30 .container,
.template31 .container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 45%;
}

.header {
  text-align: initial;
}

.template1 .avatar,
.template2 .avatar,
.template3 .avatar,
.template4 .avatar,
.template7 .avatar,
.template8 .avatar,
.template9 .avatar,
.template10 .avatar,
.template11 .avatar,
.template12 .avatar,
.template13 .avatar,
.template14 .avatar,
.template15 .avatar,
.template16 .avatar,
.template17 .avatar,
.template18 .avatar,
.template19 .avatar,
.template20 .avatar,
.template21 .avatar,
.template22 .avatar,
.template23 .avatar,
.template24 .avatar,
.template25 .avatar,
.template26 .avatar,
.template27 .avatar,
.template28 .avatar,
.template29 .avatar,
.template30 .avatar,
.template31 .avatar {
  border-radius: 50%;
  max-width: 100px;
  max-height: 100px;
  border: 1px solid #f2f2f2;
  border-width: 2px;
  margin-bottom: 1%;
}

.buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.template1-btn {
  color: #33f8fe;
  border-radius: 10px;
  border: 1px solid #33f8fe;
  width: 100;
  padding: 2%;
  background-color: #3423d9;
}

.template1-btn:hover {
  background-color: transparent;
}

.contact1 {
  background-color: #3423d9;
  border-radius: 10px;
  color: #33f8fe;
  border: 1px solid #33f8fe;
  padding: 2%;
}

.input1 {
  border: 1px solid #33f8fe;
  border-radius: 10px;
  background-color: transparent;
  outline: none;
  width: 100%;
  padding: 2%;
  margin: 1% 0;
}
.input1::placeholder {
  color: #33f8fe;
}
.submitbtn1 {
  border: 1px solid #33f8fe;
  border-radius: 10px;
  background-color: #33f8fe;
  color: #3423d9;
  padding: 2%;
  width: 100%;
}
.details1 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  font-weight: bold;
  color: #33f8fe;
}

/* =========================Template2===================== */
.template2 {
  background-image: url("./assets/Image/bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.temp2-btn {
  color: #777777;
  border-radius: 10px;
  border: 1px solid #fff;
  line-height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
}
.temp2-btn:hover {
  background-color: transparent;
  color: #fff;
}
.contact2 {
  border: 1px solid #e0ccb4;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: rgba(255, 255, 255, 0.6);
}

.template2 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input2 {
  background-color: transparent;
  border: 1px solid #000;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
}
.submitbtn2 {
  border: 1px solid #fff;
  padding: 2%;
  color: #1b2519;
  background-color: #fff;
  border-radius: 10px;
}
.details2 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}

/* =========================Template3===================== */
.template3 {
  background-image: url("./assets/Image/bg3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: black;
}
.header3 {
  margin: auto;
  text-align: center;
}

.p {
  border: 1px solid black;
  width: 100%;
  margin: 2% auto;
  padding: 2%;
  border-radius: 15px;
}
.layer {
  background-color: rgba(255, 255, 255, 0.6);
  margin: auto;
  margin-top: 8%;
  border-radius: 20px;
  width: 100%;
  padding: 15px;
}

.contact3 {
  border: 1px solid #000;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: transparent;
}

.input3 {
  background-color: transparent;
  border: 1px solid #000;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
}
.submitbtn3 {
  padding: 2%;
  color: #fff;
  background-color: #000;
  border-radius: 10px;
}

.details3 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}

/* =========================Template4===================== */
.template4 {
  background-image: url("./assets/Image/bg4.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #e0ccb4;
}

.template4.icon {
  background-color: transparent;
  border: 3px solid #e0ccb4;
  border-radius: 50%;
  padding: 5px;
}
/* .icon{
      background-color: transparent;
      border: 3px solid  #E0CCB4;
      border-radius: 50%;
      padding: 5px;
    } */

.template4-btn,
.input {
  background-color: transparent;
  border: 1px solid #e0ccb4;
  color: #e0ccb4;
  width: 100%;
  border-radius: 30px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
}

.input::placeholder {
  color: #e0ccb4;
}

.contact4 {
  border: 1px solid #e0ccb4;
  border-radius: 30px;
  padding: 2%;
}
.template4 .form {
  display: flex;
  color: #e0ccb4;
  flex-direction: column;
  justify-content: center;
}
.submitbtn4 {
  border: 1px solid #e0ccb4;
  padding: 2%;
  color: #1b2519;
  background-color: #e0ccb4;
  font-weight: bold;
  border-radius: 30px;
}
.details4 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  font-weight: bold;
}

/* =========================Template5==================== */
.template5 {
  background-image: linear-gradient(#159555, #155798);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
}

.template5 .avatar,
.template6 .avatar {
  border-radius: 50%;
  max-width: 100px;
  max-height: 100px;
  border-width: 2px;
  margin-bottom: 1%;
}

.template5-btn {
  border: 1px solid #fff;
  border-radius: 30px;
  background-color: transparent;
  padding: 2%;
  color: #fff;
  font-size: smaller;
  margin: 1% auto;
  width: 100%;
}
.template5 .form {
  display: flex;
  color: #e0ccb4;
  flex-direction: column;
  justify-content: center;
}
.contact5 {
  border: 1px solid white;
  padding: 1%;
  border-radius: 20px;
}
.submitbtn5 {
  padding: 2%;
  color: #0e716e;
  background-color: white;
  border: 1px solid white;
  font-weight: bold;
  border-radius: 30px;
}
.details5 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  font-weight: bold;
}

.input5 {
  border: none;
  border-bottom: 1px solid white;
  background-color: transparent;
  outline: none;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}
.input5::placeholder {
  color: white;
  font-weight: bold;
  font-size: small;
}

.social-media-list .i {
  color: #fff;
}

.vedio {
  border-radius: 30px;
}

@media only screen and (max-width: 600px) {
  .template1 .container,
  .template2 .container,
  .template3 .container,
  .template4 .container,
  .template5 .container,
  .template6 .container,
  .template7 .container,
  .template8 .congtainer,
  .template18 .container {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .template1 .container,
  .template2 .container,
  .template3 .container,
  .template4 .container,
  .template5 .container,
  .template6 .container,
  .template7 .container,
  .template8 .congtainer,
  .template18 .container {
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .template1 .container,
  .template2 .container,
  .template3 .container,
  .template4 .container,
  .template5 .container,
  .template6 .container,
  .template7 .container,
  .template8 .congtainer,
  .template18 .container {
    width: 80%;
  }
}
@media only screen and (max-width: 1200px) {
  .template1 .container,
  .template2 .container,
  .template3 .container,
  .template4 .container,
  .template5 .container,
  .template6 .container,
  .template7 .container,
  .template8 .congtainer,
  .template18 .container {
    width: 80%;
  }
}
/* =========================Template6==================== */
.template6 {
  background-image: linear-gradient(#1e2671, #c33764);
  color: #fff;
  background-position: center;
  background-attachment: fixed;
}

.template6-Btn {
  background-color: rgba(207, 205, 255, 1);
  border-radius: 10px;
  border: 2px solid #000;
  padding: 2%;
  box-shadow: #000 12px 12px 0 0;
}

.template6-Btn:hover {
  transform: translate(5px, 5px);
  box-shadow: #000 8px 8px 0 0;
}

.template6 form {
  display: flex;
  color: #e0ccb4;
  flex-direction: column;
  justify-content: center;
}

.contact6 {
  background-color: rgba(207, 205, 255, 1);
  border-radius: 10px;
  border: 1px solid #000;
  padding: 2%;
  color: black;
  box-shadow: #000 12px 12px 0 0;
}

.input6 {
  border: none;
  background-color: transparent;
  outline: none;
  border-bottom: 1px solid #000;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}

.submitbtn6 {
  border: 1px solid #000;
  border-radius: 10px;
  background-color: #000;
  color: rgba(207, 205, 255, 1);
  padding: 2%;
}
.contt6 {
  color: black;
}
.details6 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  font-weight: bold;
  color: #000;
}

/* =========================Template7==================== */
.template7 {
  background-image: url("./assets/Image/bg7.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  height: max-content;
}

.frame {
  background-color: transparent;
  width: 100%;
  padding: 5px 5px;
  border: 1px solid #ffff;
}

.template7-btn {
  width: 100%;
  background-color: #fff;
  padding: 2%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: smaller;
  border: none;
  color: #922588;
}
.template7-btn:hover {
  background-color: transparent;
  color: #ffff;
}
.subscribe {
  background-color: #333433;
  color: #fff;
  width: 100%;
}
.subscribe-btn {
  background-color: red;
  color: #fff;
  border-radius: 10px;
  padding: 9%;
  border: none;
}

.template7 .form {
  display: flex;
  background-color: #fff;
  color: #e0ccb4;
  flex-direction: column;
  justify-content: center;
}

.contact7 {
  border: 1px solid white;
  padding: 2%;
  background-color: #fff;
  color: #922588;
}
.submitbtn7 {
  padding: 2%;
  color: #fff;
  background-color: #922588;
  border: 1px solid #922588;
  font-weight: bold;
}
.details7 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}

.input7 {
  border: 1px solid #922588;
  background-color: transparent;
  color: #922588;
  outline: none;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}

.input7::placeholder {
  color: #922588;
}
.contt7 {
  color: #922588;
}

/* =========================Template8==================== */
.template8 {
  background-image: linear-gradient(#1b2846, #da6b8f, #f5e4b1);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
}

.template8-btn {
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid #f9d4c1;
  width: 100%;
  border-radius: 30px;
  box-shadow: #f9d4c1 12px 12px 0 0;
  padding: 2%;
  text-transform: capitalize;
  color: #d69d80;
}

.template8-btn:hover {
  transform: translate(5px, 5px);
  box-shadow: #f9d4c1 8px 8px 0 0;
}

.contact8 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  color: #d69d80;
  padding: 2%;
  box-shadow: #f9d4c1 12px 12px 0 0;
}

.input8 {
  border: none;
  background-color: transparent;
  outline: none;
  border: 1px solid #d69d80;
  border-radius: 30px;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}

.input8::placeholder {
  color: #d69d80;
  font-weight: bold;
  font-size: small;
}

.submitbtn8 {
  border: 1px solid #f9d4c1;
  border-radius: 30px;
  background-color: #f9d4c1;
  color: rgba(255, 255, 255, 1);
  width: 100%;
  padding: 2%;
}

.details8 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: #d69d80;
}

.request:hover {
  transform: translate(5px, 5px);
  box-shadow: #f9d4c1 8px 8px 0 0;
}

/* =============================temp9===================== */
.template9 {
  background-image: linear-gradient(#ef639f, #eecca3);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.temp9-btn {
  color: #777777;
  border-radius: 10px;
  border: 1px solid #fff;
  line-height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
}
.temp9-btn:hover {
  background-color: transparent;
  color: #fff;
}
.contact9 {
  border: 3px solid #ffffff;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: transparent;
}

.template9 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input9 {
  background-color: transparent;
  border: 1px solid #ffffff;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: white;
}
.submitbtn9 {
  border: 1px solid #fff;
  padding: 2%;
  color: #1b2519;
  background-color: #fff;
  border-radius: 10px;
}
.details9 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}

.formcheck9 {
  border-radius: 20px;
}

/* =============================temp10===================== */
.template10 {
  background-image: linear-gradient(#2c3e50, #bdc3c7);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.temp10-btn {
  color: #777777;
  border-radius: 10px;
  border: 1px solid #fff;
  line-height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
}
.temp10-btn:hover {
  background-color: transparent;
  color: #fff;
}
.contact10 {
  border: 3px solid #ffffff;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: transparent;
}

.template10 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input10 {
  background-color: transparent;
  border: 1px solid #ffffff;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: white;
}
.submitbtn10 {
  border: 1px solid #fff;
  padding: 2%;
  color: #1b2519;
  background-color: #fff;
  border-radius: 10px;
}
.details10 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}

.formcheck10 {
  border-radius: 20px;
}

/* =============================temp11===================== */
.template11 {
  background-image: url(../src/assets/Image/2021mc_8-pc.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.temp11-btn {
  color: #777777;
  border-radius: 10px;
  border: 1px solid #fff;
  line-height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
}
.temp11-btn:hover {
  background-color: transparent;
  color: #fff;
}
.contact11 {
  border: 3px solid #ffffff;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: transparent;
}

.template11 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input11 {
  background-color: transparent;
  border: 1px solid #ffffff;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: white;
}
.submitbtn11 {
  border: 1px solid #fff;
  padding: 2%;
  color: #1b2519;
  background-color: #fff;
  border-radius: 10px;
}
.details11 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}
.formcheck11 {
  border-radius: 20px;
}

/* =============================temp12===================== */
.template12 {
  background-color: red;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.temp12-btn {
  color: #777777;
  border-radius: 10px;
  border: 1px solid #fff;
  line-height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
}
.temp12-btn:hover {
  background-color: transparent;
  color: #fff;
}
.contact12 {
  border: 3px solid #ffffff;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: transparent;
}

.template12 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input12 {
  background-color: transparent;
  border: 1px solid #ffffff;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: white;
}
.submitbtn12 {
  border: 1px solid #fff;
  padding: 2%;
  color: #1b2519;
  background-color: #fff;
  border-radius: 10px;
}
.details12 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}

.formcheck11 {
  border-radius: 20px;
}

/* =============================temp13===================== */
.template13 {
  background-image: linear-gradient(#b91d73, #f853c5);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.temp13-btn {
  color: #777777;
  border-radius: 10px;
  border: 1px solid #fff;
  line-height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
}
.temp13-btn:hover {
  background-color: transparent;
  color: #fff;
}
.contact13 {
  border: 3px solid #ffffff;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: transparent;
}

.template13 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input13 {
  background-color: transparent;
  border: 1px solid #ffffff;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: white;
}
.submitbtn13 {
  border: 1px solid #fff;
  padding: 2%;
  color: #1b2519;
  background-color: #fff;
  border-radius: 10px;
}
.details13 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}

.formcheck13 {
  border-radius: 20px;
}

/* =============================temp14===================== */
.template14 {
  background-image: linear-gradient(#ffc3a0, #ffafbc);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.temp14-btn {
  color: #777777;
  border-radius: 10px;
  border: 1px solid #fff;
  line-height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
}
.temp14-btn:hover {
  background-color: transparent;
  color: #fff;
}
.contact14 {
  border: 3px solid #ffffff;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: transparent;
}

.template14 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input14 {
  background-color: transparent;
  border: 1px solid #ffffff;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: white;
}
.submitbtn14 {
  border: 1px solid #fff;
  padding: 2%;
  color: #1b2519;
  background-color: #fff;
  border-radius: 10px;
}
.details14 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}
.formcheck14 {
  border-radius: 20px;
}

/* =============================temp15===================== */
.template15 {
  background-image: linear-gradient(#363895, #005c97);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.temp15-btn {
  color: #777777;
  border-radius: 10px;
  border: 1px solid #fff;
  line-height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
}
.temp15-btn:hover {
  background-color: transparent;
  color: #fff;
}
.contact15 {
  border: 3px solid #ffffff;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: transparent;
}

.template15 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input15 {
  background-color: transparent;
  border: 1px solid #ffffff;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: white;
}
.submitbtn15 {
  border: 1px solid #fff;
  padding: 2%;
  color: #1b2519;
  background-color: #fff;
  border-radius: 10px;
}
.details15 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}
.formcheck15 {
  border-radius: 20px;
}

/* =============================temp16===================== */
.template16 {
  background-image: linear-gradient(#185b9d, #43cda2);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.temp16-btn {
  color: #777777;
  border-radius: 10px;
  border: 1px solid #fff;
  line-height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
}
.temp16-btn:hover {
  background-color: transparent;
  color: #fff;
}
.contact16 {
  border: 3px solid #ffffff;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: transparent;
}

.template16 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input16 {
  background-color: transparent;
  border: 1px solid #ffffff;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: white;
}
.submitbtn16 {
  border: 1px solid #fff;
  padding: 2%;
  color: #1b2519;
  background-color: #fff;
  border-radius: 10px;
}
.details16 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}
.formcheck16 {
  border-radius: 20px;
}

/* =============================template17===================== */
.template17 {
  background-image: url("../src/assets/Image/bg17.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.frame3 {
  position: relative;
  color: #fff;
}
.frame3::before {
  position: absolute;
  top: 6px;
  left: -8px;
  content: "";
  display: block;
  width: 8px;
  height: 100%;
  background-color: #fff;
  mask-image: none;
  -webkit-mask-image: none;
}
.frame3::after {
  position: absolute;
  bottom: -8px;
  right: 8px;
  content: "";
  display: block;
  width: 100%;
  height: 8px;
  background: #fff;
  mask-image: none;
  -webkit-mask-image: none;
}
.template17-btn::before {
  position: absolute;
  top: -2px;
  left: -10px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-bottom: 6px solid #fff;
  border-left: 8px solid transparent;
}
.template17-btn {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  width: 100%;
  padding: 2%;
  outline: none;
  position: relative;
}
.template17-btn::after {
  position: absolute;
  bottom: -10px;
  right: -2px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 8px solid #fff;
  border-right: 8px solid transparent;
}

.input::placeholder {
  color: #fff;
}

.contact17 {
  border: 1px solid #fff;
  padding: 2%;
}

.request17::before {
  position: absolute;
  top: -2px;
  left: -10px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-bottom: 6px solid #fff;
  border-left: 8px solid transparent;
}
.request17 {
  border: 1px solid #fff;
  position: relative;
  padding: 2%;
}
.request17::after {
  position: absolute;
  bottom: -10px;
  right: -2px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 8px solid #fff;
  border-right: 8px solid transparent;
}
.template17 .form {
  display: flex;
  color: #e0ccb4;
  flex-direction: column;
  justify-content: center;
}
.submitbtn17 {
  border: 1px solid #fff;
  padding: 2%;
  color: #0125ad;
  background-color: #fff;
  font-weight: bold;
  border-radius: 30px;
}
.input17 {
  border: none;
  background-color: transparent;
  outline: none;
  border-bottom: 1px solid #fff;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}
.input17::placeholder {
  color: #fff;
}
.details17 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  font-weight: bold;
}

/* =============================template18===================== */
.template18 {
  background-image: url("../src/assets/Image/bg18.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}

.template18-btn {
  background-color: rgba(236, 121, 105, 1);
  color: black;
  width: 100%;
  padding: 2%;
  border: 0;
  box-shadow: none;
}
.template18-btn:hover {
  background-color: transparent;
  color: rgba(236, 121, 105, 1);
}
.input18 {
  border: none;
  background-color: transparent;
  outline: none;
  border-bottom: 1px solid #000;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}

.input18::placeholder {
  color: black;
}
.frame2 {
  border: 1px solid rgba(236, 121, 105, 1);
  background-color: transparent;
  padding: 2px 2px;
}
.contact18 {
  background-color: rgba(236, 121, 105, 1);
  color: black;
  border: 0;
  padding: 2%;
}
.template18 .form {
  display: flex;
  color: #e0ccb4;
  flex-direction: column;
  justify-content: center;
}
.submitbtn18 {
  border: 1px solid rgba(236, 121, 105, 1);
  padding: 2%;
  color: rgba(236, 121, 105, 1);
  background-color: black;
}
.details18 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}
.contt18 {
  color: black;
}

/* =============================template19===================== */
.template19 {
  background-image: url("../src/assets/Image/bg19.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.header19 {
  text-align: start;
}

.template19-btn {
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid white;
  color: #006d6a;
  width: 100%;
  padding: 2%;
  box-shadow: none;
}

.template19-btn:hover {
  background-color: transparent;
  border: 2px solid #f2ede6;
  color: white;
}
.input19 {
  border: none;
  outline: none;
  border: 1px solid #006d6a;
  background-color: transparent;
  width: 100%;
  padding: 1%;
  margin: 2% 0;
}

.input19::placeholder {
  color: #006d6a;
  padding-left: 1%;
}

.contact19 {
  background-color: rgba(255, 255, 255, 0.7);
  color: #006d6a;
  border: 2px solid white;
  padding: 2%;
}
.template19 .form {
  display: flex;
  color: #e0ccb4;
  flex-direction: column;
  justify-content: center;
}
.submitbtn19 {
  border: 1px solid #006d6a;
  padding: 1%;
  color: white;
  background-color: #006d6a;
}
.details19 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}
.contt19 {
  color: #006d6a;
}

/* =============================template20===================== */
.template20 {
  background-image: url("../src/assets/Image/bg20.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #8c285e;
  position: relative;
}

.template20-Btn {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  border: 2px solid #8c285e;
  padding: 2%;
  color: #8c285e;
  box-shadow: #8c285e 12px 12px 0 0;
}

.template20-Btn:hover {
  transform: translate(5px, 5px);
  box-shadow: #8c285e 8px 8px 0 0;
}

.template20 form {
  display: flex;
  color: #8c285e;
  flex-direction: column;
  justify-content: center;
}

.contact20 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  border: 1px solid #8c285e;
  padding: 2%;
  color: #8c285e;
  box-shadow: #8c285e 12px 12px 0 0;
}
.request2:hover {
  transform: translate(5px, 5px);
  box-shadow: #8c285e 8px 8px 0 0;
}

.input20 {
  border: 1px solid #8c285e;
  background-color: transparent;
  outline: none;
  border-radius: 30px;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}
.input20::placeholder {
  color: #8c285e;
}
.submitbtn20 {
  border: 1px solid #8c285e;
  border-radius: 30px;
  background-color: #8c285e;
  color: rgba(255, 255, 255, 1);
  padding: 2%;
}

.details20 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  font-weight: bold;
}

.icon20 {
  color: #8c285e;
  border: 2px solid #8c285e;
  border-radius: 50%;
  padding: 1%;
  font-size: 20px;
  margin-right: 2%;
}
/* =============================template21===================== */
.template21 {
  background-image: url("../src/assets/Image/bg21.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: white;
  position: relative;
}

.template21-Btn {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  border: 2px solid #aa2b17;
  padding: 1.5%;
  color: #aa2b17;
  box-shadow: #aa2b17 12px 12px 0 0;
}

.template21-Btn:hover {
  transform: translate(5px, 5px);
  box-shadow: #aa2b17 8px 8px 0 0;
}

.template21 form {
  display: flex;
  color: #aa2b17;
  flex-direction: column;
  justify-content: center;
}

.contact21 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  border: 1px solid #aa2b17;
  padding: 2%;
  color: #aa2b17;
  box-shadow: #aa2b17 12px 12px 0 0;
}
.request3:hover {
  transform: translate(5px, 5px);
  box-shadow: #aa2b17 8px 8px 0 0;
}

.input21 {
  border: 1px solid #aa2b17;
  background-color: transparent;
  outline: none;
  border-radius: 30px;
  width: 100%;
  padding: 1% 2%;
  margin: 2% 0;
}
.input21::placeholder {
  color: #aa2b17;
}
.submitbtn21 {
  border: 1px solid #aa2b17;
  border-radius: 30px;
  background-color: #aa2b17;
  color: rgba(255, 255, 255, 1);
  padding: 2%;
  /* font-family: ; */
}

.details21 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}

.icon21 {
  color: white;
  border: 3px solid white;
  border-radius: 50%;
  padding: 1%;
  font-size: 17px;
  margin-right: 2%;
}
/* =============================template22===================== */
.template22 {
  background-image: linear-gradient(#6077fa, #f877dc);
  background-attachment: fixed;
  color: white;
  position: relative;
}

.template22-Btn {
  background-color: transparent;
  border-radius: 30px;
  border: 2px solid white;
  padding: 1.5%;
  color: white;
}

.template22 form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact22 {
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid white;
  padding: 2%;
  color: white;
}

.input22 {
  border: 1px solid white;
  background-color: transparent;
  outline: none;
  border-radius: 30px;
  width: 100%;
  padding: 1% 2%;
  margin: 2% 0;
}

.input22::placeholder {
  color: white;
}
.submitbtn22 {
  border: 1px solid white;
  border-radius: 30px;
  background-color: white;
  color: #7f00ff;
  padding: 2%;
}
.details22 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}
.icon22 {
  border: 3px solid white;
  border-radius: 50%;
  padding: 1%;
  background-color: white;
  font-size: 20px;
  margin-right: 2%;
}

/* =============================template23===================== */
.template23 {
  background-color: white;
  color: #247e62;
  position: relative;
}

@keyframes buttonWave {
  100% {
    -webkit-mask-position: 680px top;
    mask-position: 680px top;
  }
  0% {
    -webkit-mask-position: 0 top;
    mask-position: 0 top;
  }
}

.frame6::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  overflow: hidden;
  mask-position: 0 top;
  mask-size: 640px 3px;
  -webkit-mask-position: 0 top;
  -webkit-mask-size: 640px 3px;
  background-color: inherit;
  animation: 12s linear 0s infinite normal none paused buttonWave;
  -webkit-animation: 12s linear 0s infinite normal none paused buttonWave;
  top: -3px;
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgMyI+PHBhdGggZD0iTTAgM1YwYzUgMCA1IDIgMTAgMnM1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMmM1LjEgMCA1LjEgMiAxMCAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyaC4xYzUgMCA1LTIgMTAtMnM1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMnYzSDB6Ii8+PC9zdmc+");
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgMyI+PHBhdGggZD0iTTAgM1YwYzUgMCA1IDIgMTAgMnM1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMmM1LjEgMCA1LjEgMiAxMCAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyaC4xYzUgMCA1LTIgMTAtMnM1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMnYzSDB6Ii8+PC9zdmc+");
}

.frame6::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  overflow: hidden;
  mask-position: 0 top;
  mask-size: 640px 3px;
  -webkit-mask-position: 0 top;
  -webkit-mask-size: 640px 3px;
  background-color: inherit;
  animation: 12s linear 0s infinite normal none paused buttonWave;
  -webkit-animation: 12s linear 0s infinite normal none paused buttonWave;
  bottom: -3px;
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgMyI+PHBhdGggZD0iTTY0MCAwdjNjLTUgMC01LTItMTAtMnMtNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDJjLTUuMSAwLTUuMS0yLTEwLTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yaC0uMWMtNSAwLTUgMi0xMCAycy01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMlM1IDMgMCAzVjBoNjQweiIvPjwvc3ZnPg==");
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgMyI+PHBhdGggZD0iTTY0MCAwdjNjLTUgMC01LTItMTAtMnMtNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDJjLTUuMSAwLTUuMS0yLTEwLTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yaC0uMWMtNSAwLTUgMi0xMCAycy01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMlM1IDMgMCAzVjBoNjQweiIvPjwvc3ZnPg==");
}

.frame6:hover:before {
  animation-play-state: running;
  -webkit-animation-play-state: running;
}

.frame6:hover:after {
  animation-name: buttonWave;
  -webkit-animation-name: buttonWave;
  animation-play-state: running;
  -webkit-animation-play-state: running;
}

.frame6 {
  background-color: #247e62;
  border: 0;
  position: relative;
}

.template23-Btn {
  background-color: #247e62;
  padding: 1.5%;
  position: relative;
  color: white;
  border: 0;
}

.template23 form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact23 {
  background-color: #247e62;
  border: 1px solid #247e62;
  padding: 2%;
  color: white;
}

.input23 {
  border: none;
  background-color: transparent;
  outline: none;
  border-bottom: 1px solid #fff;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}

.input23::placeholder {
  color: white;
}
.submitbtn23 {
  border: 1px solid white;
  border-radius: 30px;
  background-color: white;
  color: #247e62;
  padding: 2%;
}

.details23 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}

.icon23 {
  color: #247e62;
  border: 2px solid #247e62;
  border-radius: 50%;
  padding: 1%;
  font-size: 17px;
  margin-right: 2%;
}

/* =============================temp24===================== */
.template24 {
  background-image: url("assets/Image/b17.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.temp24-btn {
  color: #777777;
  border-radius: 10px;
  border: 1px solid #fff;
  line-height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
}
.temp24-btn:hover {
  background-color: transparent;
  color: #fff;
}
.contact24 {
  border: 3px solid #ffffff;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: transparent;
}

.template24 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input24 {
  background-color: transparent;
  border: 1px solid #ffffff;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: white;
}
.submitbtn24 {
  border: 1px solid #fff;
  padding: 2%;
  color: #1b2519;
  background-color: #fff;
  border-radius: 10px;
}
.details24 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}
.contt {
  color: white;
}
.formcheck9 {
  border-radius: 20px;
}
/* =============================temp25===================== */
.template25 {
  background-image: url("assets/Image/b18.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #1b2519;
  position: relative;
}

.template25 .container {
  background-image: url("./assets/Image/cb18.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.temp18-btn {
  color: #777777;
  border-radius: 10px;
  border: 1px solid #fff;
  line-height: 48px;
  background-color: rgba(255, 255, 255, 0.6);
}
.temp18-btn:hover {
  background-color: transparent;
  color: #fff;
}
.contact25 {
  border: 3px solid #1b2519;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: transparent;
}

.template25 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input25 {
  background-color: transparent;
  border: 1px solid #1b2519;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: white;
}
.submitbtn25 {
  border: 1px solid #fff;
  padding: 2%;
  color: #1b2519;
  background-color: #fff;
  border-radius: 10px;
}
.details25 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: #1b2519;
}
.contt {
  color: #1b2519;
}
.formcheck9 {
  border-radius: 20px;
}

/* =============================temp26===================== */
.template26 {
  background-image: url("assets/Image/b26.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}
.temp26-btn {
  color: #000000;
  border-radius: 30px;
  border: 3px solid #0c0c0c;
  line-height: 48px;
  background-color: transparent;
}

.contact26 {
  border: 3px solid #000000;
  border-radius: 10px;
  padding: 2%;
  color: #333333;
  background-color: transparent;
}

.template26 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input26 {
  background-color: transparent;
  border: 1px solid #000000;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: rgb(7, 7, 7);
}
.submitbtn26 {
  border: 1px solid #000000;
  padding: 2%;
  color: #1b2519;
  background-color: transparent;
  border-radius: 10px;
}
.details26 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}
.contt {
  color: rgb(0, 0, 0);
}
.formcheck9 {
  border-radius: 20px;
}

/* =============================temp27===================== */
.template27 {
  background-image: url("assets/Image/b27.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #8caff5;
  position: relative;
}
.temp27-btn {
  color: #8caff5;
  border-radius: 30px;
  border: 3px solid #8caff5;
  line-height: 48px;
  background-color: transparent;
}

.contact27 {
  border: 3px solid #8caff5;
  border-radius: 10px;
  padding: 2%;
  color: #8caff5;
  background-color: transparent;
}

.template27 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input27 {
  background-color: transparent;
  border: 1px solid #8caff5;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: #8caff5;
}
.submitbtn27 {
  border: 1px solid #8caff5;
  padding: 2%;
  color: #1b2519;
  background-color: transparent;
  border-radius: 10px;
}
.details27 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}
.contt {
  color: #8caff5;
}
.formcheck9 {
  border-radius: 20px;
}

/* =============================temp28===================== */
.template28 {
  background-image: url("assets/Image/b28.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #ffffff;
  position: relative;
}
.temp28-btn {
  color: #ffffff;
  border-radius: 30px;
  border: 3px solid #ffffff;
  line-height: 48px;
  background-color: transparent;
}

.contact28 {
  border: 3px solid #ffffff;
  border-radius: 10px;
  padding: 2%;
  color: #ffffff;
  background-color: transparent;
}

.template28 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input28 {
  background-color: transparent;
  border: 1px solid #ffffff;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: #ffffff;
}
.submitbtn28 {
  border: 1px solid #ffffff;
  padding: 2%;
  color: #1b2519;
  background-color: transparent;
  border-radius: 10px;
}
.details28 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}
.contt {
  color: #ffffff;
}
.formcheck9 {
  border-radius: 20px;
}

/* =============================temp29===================== */
.template29 {
  background-image: url("assets/Image/b29.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #000000;
  position: relative;
}
.temp29-btn {
  color: #000000;
  border-radius: 30px;
  border: 3px solid #000000;
  line-height: 48px;
  background-color: transparent;
}

.contact29 {
  border: 3px solid #000000;
  border-radius: 10px;
  padding: 2%;
  color: #000000;
  background-color: transparent;
}

.template29 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input29 {
  background-color: transparent;
  border: 1px solid #000000;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: #000000;
}
.submitbtn29 {
  border: 1px solid #000000;
  padding: 2%;
  color: #1b2519;
  background-color: transparent;
  border-radius: 10px;
}
.details29 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}
.contt {
  color: #000000;
}
.formcheck9 {
  border-radius: 20px;
}

/* =============================temp30===================== */
.template30 {
  background-image: url("assets/Image/b30.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #445e67;
  position: relative;
}
.temp30-btn {
  color: #445e67;
  border-radius: 30px;
  border: 3px solid #445e67;
  line-height: 48px;
  background-color: transparent;
}

.contact30 {
  border: 3px solid #445e67;
  border-radius: 10px;
  padding: 2%;
  color: #445e67;
  background-color: transparent;
}

.template30 .form,
.template3 .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input30 {
  background-color: transparent;
  border: 1px solid #445e67;
  width: 100%;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0;
  outline: none;
  color: #445e67;
}
.submitbtn30 {
  border: 1px solid #445e67;
  padding: 2%;
  color: #1b2519;
  background-color: transparent;
  border-radius: 10px;
}
.details30 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
  color: white;
}
.contt {
  color: #445e67;
}
.formcheck9 {
  border-radius: 20px;
}

/* =========================Template31==================== */
.template31 {
  background-image: url("./assets/Image/b31.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
  height: max-content;
}

.frame {
  background-color: transparent;
  width: 100%;
  padding: 5px 5px;
  border: 1px solid #ffff;
}

.template31-btn {
  width: 100%;
  background-color: #fff;
  padding: 2%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: smaller;
  border: none;
  color: #922588;
}
.template31-btn:hover {
  background-color: transparent;
  color: #ffff;
}
.subscribe {
  background-color: #333433;
  color: #fff;
  width: 100%;
}
.subscribe-btn {
  background-color: red;
  color: #fff;
  border-radius: 10px;
  padding: 9%;
  border: none;
}

.template31 .form {
  display: flex;
  background-color: #fff;
  color: #e0ccb4;
  flex-direction: column;
  justify-content: center;
}

.contact31 {
  border: 1px solid white;
  padding: 2%;
  background-color: #fff;
  color: #000000;
}
.submitbtn31 {
  padding: 2%;
  color: #fff;
  background-color: #000000;
  border: 1px solid #000000;
  font-weight: bold;
}
.details31 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}

.input31 {
  border: 1px solid #922588;
  background-color: transparent;
  color: #922588;
  outline: none;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}

.input31::placeholder {
  color: #922588;
}
.contt31 {
  color: #922588;
}

/* =============================template32===================== */
.template32 {
  background-image: url("./assets/Image/bg32.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #f6a948;
  position: relative;
}

.template32-Btn {
  background-color: transparent;
  padding: 2%;
  color: #f6a948;
  border: 1px solid #f6a948;
  border-radius: 20px;
}

.template32 form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact32 {
  background-color: transparent;
  border: 1px solid #f6a948;
  padding: 2%;
  color: #f6a948;
  border-radius: 10px;
}

.input32 {
  background-color: transparent;
  outline: none;
  border: 1px solid #f6a948;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
  border-radius: 10px;
}

.input32::placeholder {
  color: #f6a948;
}
.submitbtn32 {
  border: 1px solid #f6a948;
  border-radius: 10px;
  background-color: #f6a948;
  color: white;
  padding: 2%;
}

.details32 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}
/* =============================template33===================== */
.template33 {
  background-image: url("../src/assets/Image/bg33.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #9f2b1e;
  position: relative;
}

.template33-Btn {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  border: 2px solid #9f2b1e;
  padding: 2%;
  color: #9f2b1e;
  box-shadow: #9f2b1e 12px 12px 0 0;
}

.template33-Btn:hover {
  transform: translate(5px, 5px);
  box-shadow: #9f2b1e 8px 8px 0 0;
}

.template33 form {
  display: flex;
  color: #9f2b1e;
  flex-direction: column;
  justify-content: center;
}

.contact33 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  border: 1px solid #9f2b1e;
  padding: 2%;
  color: #9f2b1e;
  box-shadow: #9f2b1e 12px 12px 0 0;
}
.request4:hover {
  transform: translate(5px, 5px);
  box-shadow: #9f2b1e 8px 8px 0 0;
}

.input33 {
  border: 1px solid #9f2b1e;
  background-color: transparent;
  outline: none;
  border-radius: 10px;
  width: 100%;
  padding: 1% 2%;
  margin: 2% 0;
}
.input33::placeholder {
  color: #9f2b1e;
}
.submitbtn33 {
  border: 1px solid #9f2b1e;
  border-radius: 30px;
  background-color: #9f2b1e;
  color: rgba(255, 255, 255, 1);
  padding: 2%;
}

.details33 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}

.icon33 {
  color: #9f2b1e;
  border: 2px solid #9f2b1e;
  border-radius: 50%;
  padding: 1%;
  font-size: 17px;
  margin-right: 2%;
}

/* =============================template34===================== */
.template34 {
  background-image: url("../src/assets/Image/bg34.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #fbe8b8;
  position: relative;
}

@keyframes buttonWave {
  100% {
    -webkit-mask-position: 680px top;
    mask-position: 680px top;
  }
  0% {
    -webkit-mask-position: 0 top;
    mask-position: 0 top;
  }
}

.frame5::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  overflow: hidden;
  mask-position: 0 top;
  mask-size: 640px 3px;
  -webkit-mask-position: 0 top;
  -webkit-mask-size: 640px 3px;
  background-color: inherit;
  animation: 12s linear 0s infinite normal none paused buttonWave;
  -webkit-animation: 12s linear 0s infinite normal none paused buttonWave;
  top: -3px;
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgMyI+PHBhdGggZD0iTTAgM1YwYzUgMCA1IDIgMTAgMnM1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMmM1LjEgMCA1LjEgMiAxMCAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyaC4xYzUgMCA1LTIgMTAtMnM1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMnYzSDB6Ii8+PC9zdmc+");
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgMyI+PHBhdGggZD0iTTAgM1YwYzUgMCA1IDIgMTAgMnM1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMmM1LjEgMCA1LjEgMiAxMCAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyIDUgMCA1LTIgMTAtMmguMWM1IDAgNSAyIDkuOSAyaC4xYzUgMCA1LTIgMTAtMnM1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMiA1IDIgMTAgMiA1LTIgMTAtMnYzSDB6Ii8+PC9zdmc+");
}

.frame5::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  overflow: hidden;
  mask-position: 0 top;
  mask-size: 640px 3px;
  -webkit-mask-position: 0 top;
  -webkit-mask-size: 640px 3px;
  background-color: inherit;
  animation: 12s linear 0s infinite normal none paused buttonWave;
  -webkit-animation: 12s linear 0s infinite normal none paused buttonWave;
  bottom: -3px;
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgMyI+PHBhdGggZD0iTTY0MCAwdjNjLTUgMC01LTItMTAtMnMtNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDJjLTUuMSAwLTUuMS0yLTEwLTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yaC0uMWMtNSAwLTUgMi0xMCAycy01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMlM1IDMgMCAzVjBoNjQweiIvPjwvc3ZnPg==");
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgMyI+PHBhdGggZD0iTTY0MCAwdjNjLTUgMC01LTItMTAtMnMtNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDItNS0yLTEwLTItNSAyLTEwIDJjLTUuMSAwLTUuMS0yLTEwLTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yLTUgMC01IDItMTAgMmgtLjFjLTUgMC01LTItOS45LTItNSAwLTUgMi0xMCAyaC0uMWMtNSAwLTUtMi05LjktMi01IDAtNSAyLTEwIDJoLS4xYy01IDAtNS0yLTkuOS0yaC0uMWMtNSAwLTUgMi0xMCAycy01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMi01IDItMTAgMi01LTItMTAtMlM1IDMgMCAzVjBoNjQweiIvPjwvc3ZnPg==");
}

.frame5:hover:before {
  animation-play-state: running;
  -webkit-animation-play-state: running;
}

.frame5:hover:after {
  animation-name: buttonWave;
  -webkit-animation-name: buttonWave;
  animation-play-state: running;
  -webkit-animation-play-state: running;
}

.frame5 {
  background-color: #fbe8b8;
  border: 0;
  position: relative;
}

.template34-Btn {
  background-color: transparent;
  position: relative;
  color: #9f2b1e;
  border: 0;
  min-height: 48px;
}

.template34 form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact34 {
  background-color: #fbe8b8;
  border: 1px solid #fbe8b8;
  padding: 2%;
  color: #9f2b1e;
}

.input34 {
  background-color: transparent;
  outline: none;
  border: 1px solid #9f2b1e;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}

.input34::placeholder {
  color: #9f2b1e;
}
.submitbtn34 {
  border: 1px solid #9f2b1e;
  border-radius: 30px;
  background-color: #9f2b1e;
  color: #fbe8b8;
  padding: 2%;
}

.details34 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}
.icon34 {
  border: 3px solid white;
  border-radius: 50%;
  padding: 1%;
  background-color: white;
  font-size: 20px;
  margin-right: 2%;
}

/* =============================template35===================== */
.template35 {
  background-image: url("../src/assets/Image/bg35.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #541c03;
  position: relative;
}

.template35-Btn {
  background-color: #541c03;
  color: white;
  width: 100%;
  padding: 2%;
  border: 0;
  box-shadow: none;
}
.template35-Btn:hover {
  background-color: transparent;
  color: #541c03;
}
.input35 {
  background-color: transparent;
  outline: none;
  border: 1px solid white;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}

.input35::placeholder {
  color: white;
}
.frame4 {
  border: 1px solid #541c03;
  background-color: transparent;
  padding: 3px 3px;
}

.contact35 {
  background-color: #541c03;
  color: white;
  border: 1px solid #541c03;
  padding: 2%;
}
.template35 .form {
  display: flex;
  color: #e0ccb4;
  flex-direction: column;
  justify-content: center;
}
.submitbtn35 {
  border: 1px solid #541c03;
  padding: 2%;
  color: #541c03;
  background-color: white;
}
.details35 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}
.icon35 {
  color: #541c03;
  border: 2px solid #541c03;
  border-radius: 50%;
  padding: 1%;
  font-size: 17px;
  margin-right: 2%;
}

/* =============================template36===================== */
.template36 {
  background-image: url("../src/assets/Image/bg36.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #44423e;
  position: relative;
}

.template36-Btn {
  background-color: #e19450;
  color: white;
  width: 100%;
  padding: 2%;
  border: 1px solid #e19450;
  border-radius: 30px;
}
.template36-Btn:hover {
  background-color: transparent;
  border: 1px solid #e19450;
  color: #e19450;
}
.input36 {
  border: none;
  background-color: transparent;
  outline: none;
  border-bottom: 1px solid white;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}

.input36::placeholder {
  color: white;
}

.contact36 {
  background-color: #e19450;
  color: white;
  border: 1px solid #e19450;
  border-radius: 30px;
  padding: 2%;
}
.template36 .form {
  display: flex;
  color: #e0ccb4;
  flex-direction: column;
  justify-content: center;
}
.submitbtn36 {
  border: 1px solid white;
  padding: 2%;
  color: #e19450;
  background-color: white;
  border-radius: 30px;
}
.details36 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}

.icon36 {
  color: #44423e;
  border: 2px solid #44423e;
  border-radius: 50%;
  padding: 1%;
  font-size: 17px;
  margin-right: 2%;
}

/* =============================template37===================== */
.template37 {
  background-image: url("../src/assets/Image/bg37.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #ffaa00;
  position: relative;
}

.template37-Btn {
  background-color: transparent;
  color: #ffaa00;
  width: 100%;
  padding: 2%;
  border: 1px solid #ffaa00;
  border-radius: 10px;
}

.input37 {
  border: none;
  background-color: transparent;
  outline: none;
  border-bottom: 1px solid #ffaa00;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}

.input37::placeholder {
  color: #ffaa00;
}

.contact37 {
  background-color: transparent;
  color: #ffaa00;
  border: 1px solid #ffaa00;
  border-radius: 10px;
  padding: 2%;
}
.template37 .form {
  display: flex;
  color: #ffaa00;
  flex-direction: column;
  justify-content: center;
}
.submitbtn37 {
  border: 1px solid #ffaa00;
  padding: 2%;
  color: white;
  background-color: #ffaa00;
  border-radius: 10px;
}
.details37 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}
.icon37 {
  color: #ffaa00;
  border: 3px solid #ffaa00;
  border-radius: 50%;
  padding: 1%;
  font-size: 16px;
  margin-right: 2%;
}
/* =============================template38===================== */
.template38 {
  background-image: url("../src/assets/Image/bg38.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: white;
  position: relative;
}

.template38-Btn {
  background-color: rgba(17, 17, 17, 1);
  color: #ffff00;
  width: 100%;
  padding: 2%;
  border: 1px solid #ffff00;
  border-radius: 10px;
}
.template38-Btn:hover {
  background-color: transparent;
}
.input38 {
  border: none;
  background-color: rgba(17, 17, 17, 1);
  outline: none;
  border: 1px solid #ffff00;
  border-radius: 10px;
  width: 100%;
  padding: 1.5%;
  margin: 2% 0;
}

.input38::placeholder {
  color: #ffff00;
}

.contact38 {
  background-color: rgba(17, 17, 17, 1);
  color: #ffff00;
  border: 1px solid #ffff00;
  border-radius: 10px;
  padding: 2%;
}
.template38 .form {
  display: flex;
  color: #ffff00;
  flex-direction: column;
  justify-content: center;
}
.submitbtn38 {
  border: 1px solid #ffff00;
  padding: 2%;
  color: rgba(17, 17, 17, 1);
  background-color: #ffff00;
  border-radius: 10px;
}
.details38 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}
/* =============================template38===================== */
.template39 {
  background-image: url("../src/assets/Image/bg39.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #ffdddd;
  position: relative;
}
.template39-Btn {
  background-color: transparent;
  color: white;
  border: 1px solid #ff7777;
  border-radius: 30px;
  min-height: 48px;
}
.template39-Btn:hover {
  color: #ff7777;
}
.template39 form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact39 {
  background-color: transparent;
  border: 1px solid #ff7777;
  padding: 2%;
  border-radius: 30px;
  color: white;
}

.input39 {
  background-color: transparent;
  outline: none;
  border: 1px solid white;
  border-radius: 30px;
  width: 100%;
  padding: 2%;
  margin: 2% 0;
}

.input39::placeholder {
  color: white;
}
.submitbtn39 {
  border: 1px solid #ff7777;
  border-radius: 30px;
  background-color: #ff7777;
  color: white;
  padding: 2%;
}

.details39 {
  width: 70%;
  margin: 2% auto;
  text-align: center;
  font-size: smaller;
}

.icon39 {
  border: 3px solid white;
  border-radius: 50%;
  padding: 1%;
  color: white;
  font-size: 20px;
  margin-right: 2%;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  animation: animStar 50s linear infinite;
  box-shadow: 779px 1331px #fff, 324px 42px #fff, 303px 586px #fff,
    1312px 276px #fff, 451px 625px #fff, 521px 1931px #fff, 1087px 1871px #fff,
    36px 1546px #fff, 132px 934px #fff, 1698px 901px #fff, 1418px 664px #fff,
    1448px 1157px #fff, 1084px 232px #fff, 347px 1776px #fff, 1722px 243px #fff,
    1629px 835px #fff, 479px 969px #fff, 1231px 960px #fff, 586px 384px #fff,
    164px 527px #fff, 8px 646px #fff, 1150px 1126px #fff, 665px 1357px #fff,
    1556px 1982px #fff, 1260px 1961px #fff, 1675px 1741px #fff,
    1843px 1514px #fff, 718px 1628px #fff, 242px 1343px #fff, 1497px 1880px #fff,
    1364px 230px #fff, 1739px 1302px #fff, 636px 959px #fff, 304px 686px #fff,
    614px 751px #fff, 1935px 816px #fff, 1428px 60px #fff, 355px 335px #fff,
    1594px 158px #fff, 90px 60px #fff, 1553px 162px #fff, 1239px 1825px #fff,
    1945px 587px #fff, 749px 1785px #fff, 1987px 1172px #fff, 1301px 1237px #fff,
    1039px 342px #fff, 1585px 1481px #fff, 995px 1048px #fff, 524px 932px #fff,
    214px 413px #fff, 1701px 1300px #fff, 1037px 1613px #fff, 1871px 996px #fff,
    1360px 1635px #fff, 1110px 1313px #fff, 412px 1783px #fff, 1949px 177px #fff,
    903px 1854px #fff, 700px 1936px #fff, 378px 125px #fff, 308px 834px #fff,
    1118px 962px #fff, 1350px 1929px #fff, 781px 1811px #fff, 561px 137px #fff,
    757px 1148px #fff, 1670px 1979px #fff, 343px 739px #fff, 945px 795px #fff,
    576px 1903px #fff, 1078px 1436px #fff, 1583px 450px #fff, 1366px 474px #fff,
    297px 1873px #fff, 192px 162px #fff, 1624px 1633px #fff, 59px 453px #fff,
    82px 1872px #fff, 1933px 498px #fff, 1966px 1974px #fff, 1975px 1688px #fff,
    779px 314px #fff, 1858px 1543px #fff, 73px 1507px #fff, 1693px 975px #fff,
    1683px 108px #fff, 1768px 1654px #fff, 654px 14px #fff, 494px 171px #fff,
    1689px 1895px #fff, 1660px 263px #fff, 1031px 903px #fff, 1203px 1393px #fff,
    1333px 1421px #fff, 1113px 41px #fff, 1206px 1645px #fff, 1325px 1635px #fff,
    142px 388px #fff, 572px 215px #fff, 1535px 296px #fff, 1419px 407px #fff,
    1379px 1003px #fff, 329px 469px #fff, 1791px 1652px #fff, 935px 1802px #fff,
    1330px 1820px #fff, 421px 1933px #fff, 828px 365px #fff, 275px 316px #fff,
    707px 960px #fff, 1605px 1554px #fff, 625px 58px #fff, 717px 1697px #fff,
    1669px 246px #fff, 1925px 322px #fff, 1154px 1803px #fff, 1929px 295px #fff,
    1248px 240px #fff, 1045px 1755px #fff, 166px 942px #fff, 1888px 1773px #fff,
    678px 1963px #fff, 1370px 569px #fff, 1974px 1400px #fff, 1786px 460px #fff,
    51px 307px #fff, 784px 1400px #fff, 730px 1258px #fff, 1712px 393px #fff,
    416px 170px #fff, 1797px 1932px #fff, 572px 219px #fff, 1557px 1856px #fff,
    218px 8px #fff, 348px 1334px #fff, 469px 413px #fff, 385px 1738px #fff,
    1357px 1818px #fff, 240px 942px #fff, 248px 1847px #fff, 1535px 806px #fff,
    236px 1514px #fff, 1429px 1556px #fff, 73px 1633px #fff, 1398px 1121px #fff,
    671px 1301px #fff, 1404px 1663px #fff, 740px 1018px #fff, 1600px 377px #fff,
    785px 514px #fff, 112px 1084px #fff, 1915px 1887px #fff, 1463px 1848px #fff,
    687px 1115px #fff, 1268px 1768px #fff, 1729px 1425px #fff,
    1284px 1022px #fff, 801px 974px #fff, 1975px 1317px #fff, 1354px 834px #fff,
    1446px 1484px #fff, 1283px 1786px #fff, 11px 523px #fff, 1842px 236px #fff,
    1355px 654px #fff, 429px 7px #fff, 1033px 1128px #fff, 157px 297px #fff,
    545px 635px #fff, 52px 1080px #fff, 827px 1520px #fff, 1121px 490px #fff,
    9px 309px #fff, 1744px 1586px #fff, 1014px 417px #fff, 1534px 524px #fff,
    958px 552px #fff, 1403px 1496px #fff, 387px 703px #fff, 1522px 548px #fff,
    1355px 282px #fff, 1532px 601px #fff, 1838px 790px #fff, 290px 259px #fff,
    295px 598px #fff, 1601px 539px #fff, 1561px 1272px #fff, 34px 1922px #fff,
    1024px 543px #fff, 467px 369px #fff, 722px 333px #fff, 1976px 1255px #fff,
    766px 983px #fff, 1582px 1285px #fff, 12px 512px #fff, 617px 1410px #fff,
    682px 577px #fff, 1334px 1438px #fff, 439px 327px #fff, 1617px 1661px #fff,
    673px 129px #fff, 794px 941px #fff, 1386px 1902px #fff, 37px 1353px #fff,
    1467px 1353px #fff, 416px 18px #fff, 187px 344px #fff, 200px 1898px #fff,
    1491px 1619px #fff, 811px 347px #fff, 924px 1827px #fff, 945px 217px #fff,
    1735px 1228px #fff, 379px 1890px #fff, 79px 761px #fff, 825px 1837px #fff,
    1980px 1558px #fff, 1308px 1573px #fff, 1488px 1726px #fff,
    382px 1208px #fff, 522px 595px #fff, 1277px 1898px #fff, 354px 552px #fff,
    161px 1784px #fff, 614px 251px #fff, 526px 1576px #fff, 17px 212px #fff,
    179px 996px #fff, 467px 1208px #fff, 1944px 1838px #fff, 1140px 1093px #fff,
    858px 1007px #fff, 200px 1064px #fff, 423px 1964px #fff, 1945px 439px #fff,
    1377px 689px #fff, 1120px 1437px #fff, 1876px 668px #fff, 907px 1324px #fff,
    343px 1976px #fff, 1816px 1501px #fff, 1849px 177px #fff, 647px 91px #fff,
    1984px 1012px #fff, 1336px 1300px #fff, 128px 648px #fff, 305px 1060px #fff,
    1324px 826px #fff, 1263px 1314px #fff, 1801px 629px #fff, 1614px 1555px #fff,
    1634px 90px #fff, 1603px 452px #fff, 891px 1984px #fff, 1556px 1906px #fff,
    121px 68px #fff, 1676px 1714px #fff, 516px 936px #fff, 1947px 1492px #fff,
    1455px 1519px #fff, 45px 602px #fff, 205px 1039px #fff, 793px 172px #fff,
    1562px 1739px #fff, 1056px 110px #fff, 1512px 379px #fff, 1795px 1621px #fff,
    1848px 607px #fff, 262px 1719px #fff, 477px 991px #fff, 483px 883px #fff,
    1239px 1197px #fff, 1496px 647px #fff, 1649px 25px #fff, 1491px 1946px #fff,
    119px 996px #fff, 179px 1472px #fff, 1341px 808px #fff, 1565px 1700px #fff,
    407px 1544px #fff, 1754px 357px #fff, 1288px 981px #fff, 902px 1997px #fff,
    1755px 1668px #fff, 186px 877px #fff, 1202px 1882px #fff, 461px 1213px #fff,
    1400px 748px #fff, 1969px 1899px #fff, 809px 522px #fff, 514px 1219px #fff,
    374px 275px #fff, 938px 1973px #fff, 357px 552px #fff, 144px 1722px #fff,
    1572px 912px #fff, 402px 1858px #fff, 1544px 1195px #fff, 667px 1257px #fff,
    727px 1496px #fff, 993px 232px #fff, 1772px 313px #fff, 1040px 1590px #fff,
    1204px 1973px #fff, 1268px 79px #fff, 1555px 1048px #fff, 986px 1707px #fff,
    978px 1710px #fff, 713px 360px #fff, 407px 863px #fff, 461px 736px #fff,
    284px 1608px #fff, 103px 430px #fff, 1283px 1319px #fff, 977px 1186px #fff,
    1966px 1516px #fff, 1287px 1129px #fff, 70px 1098px #fff, 1189px 889px #fff,
    1126px 1734px #fff, 309px 1292px #fff, 879px 764px #fff, 65px 473px #fff,
    1003px 1959px #fff, 658px 791px #fff, 402px 1576px #fff, 35px 622px #fff,
    529px 1589px #fff, 164px 666px #fff, 1876px 1290px #fff, 1541px 526px #fff,
    270px 1297px #fff, 440px 865px #fff, 1500px 802px #fff, 182px 1754px #fff,
    1264px 892px #fff, 272px 1249px #fff, 1289px 1535px #fff, 190px 1646px #fff,
    955px 242px #fff, 1456px 1597px #fff, 1727px 1983px #fff, 635px 801px #fff,
    226px 455px #fff, 1396px 1710px #fff, 849px 1863px #fff, 237px 1264px #fff,
    839px 140px #fff, 1122px 735px #fff, 1280px 15px #fff, 1318px 242px #fff,
    1819px 1148px #fff, 333px 1392px #fff, 1949px 553px #fff, 1878px 1332px #fff,
    467px 548px #fff, 1812px 1082px #fff, 1067px 193px #fff, 243px 156px #fff,
    483px 1616px #fff, 1714px 933px #fff, 759px 1800px #fff, 1822px 995px #fff,
    1877px 572px #fff, 581px 1084px #fff, 107px 732px #fff, 642px 1837px #fff,
    166px 1493px #fff, 1555px 198px #fff, 819px 307px #fff, 947px 345px #fff,
    827px 224px #fff, 927px 1394px #fff, 540px 467px #fff, 1093px 405px #fff,
    1140px 927px #fff, 130px 529px #fff, 33px 1980px #fff, 1147px 1663px #fff,
    1616px 1436px #fff, 528px 710px #fff, 798px 1100px #fff, 505px 1480px #fff,
    899px 641px #fff, 1909px 1949px #fff, 1311px 964px #fff, 979px 1301px #fff,
    1393px 969px #fff, 1793px 1886px #fff, 292px 357px #fff, 1196px 1718px #fff,
    1290px 1994px #fff, 537px 1973px #fff, 1181px 1674px #fff,
    1740px 1566px #fff, 1307px 265px #fff, 922px 522px #fff, 1892px 472px #fff,
    384px 1746px #fff, 392px 1098px #fff, 647px 548px #fff, 390px 1498px #fff,
    1246px 138px #fff, 730px 876px #fff, 192px 1472px #fff, 1790px 1789px #fff,
    928px 311px #fff, 1253px 1647px #fff, 747px 1921px #fff, 1561px 1025px #fff,
    1533px 1292px #fff, 1985px 195px #fff, 728px 729px #fff, 1712px 1936px #fff,
    512px 1717px #fff, 1528px 483px #fff, 313px 1642px #fff, 281px 1849px #fff,
    1212px 799px #fff, 435px 1191px #fff, 1422px 611px #fff, 1718px 1964px #fff,
    411px 944px #fff, 210px 636px #fff, 1502px 1295px #fff, 1434px 349px #fff,
    769px 60px #fff, 747px 1053px #fff, 789px 504px #fff, 1436px 1264px #fff,
    1893px 1225px #fff, 1394px 1788px #fff, 1108px 1317px #fff,
    1673px 1395px #fff, 854px 1010px #fff, 1705px 80px #fff, 1858px 148px #fff,
    1729px 344px #fff, 1388px 664px #fff, 895px 406px #fff, 1479px 157px #fff,
    1441px 1157px #fff, 552px 1900px #fff, 516px 364px #fff, 1647px 189px #fff,
    1427px 1071px #fff, 785px 729px #fff, 1080px 1710px #fff, 504px 204px #fff,
    1177px 1622px #fff, 657px 34px #fff, 1296px 1099px #fff, 248px 180px #fff,
    1212px 1568px #fff, 667px 1562px #fff, 695px 841px #fff, 1608px 1247px #fff,
    751px 882px #fff, 87px 167px #fff, 607px 1368px #fff, 1363px 1203px #fff,
    1836px 317px #fff, 1668px 1703px #fff, 830px 1154px #fff, 1721px 1398px #fff,
    1601px 1280px #fff, 976px 874px #fff, 1743px 254px #fff, 1020px 1815px #fff,
    1670px 1766px #fff, 1890px 735px #fff, 1379px 136px #fff, 1864px 695px #fff,
    206px 965px #fff, 1404px 1932px #fff, 1923px 1360px #fff, 247px 682px #fff,
    519px 1708px #fff, 645px 750px #fff, 1164px 1204px #fff, 834px 323px #fff,
    172px 1350px #fff, 213px 972px #fff, 1837px 190px #fff, 285px 1806px #fff,
    1047px 1299px #fff, 1548px 825px #fff, 1730px 324px #fff, 1346px 1909px #fff,
    772px 270px #fff, 345px 1190px #fff, 478px 1433px #fff, 1479px 25px #fff,
    1994px 1830px #fff, 1744px 732px #fff, 20px 1635px #fff, 690px 1795px #fff,
    1594px 569px #fff, 579px 245px #fff, 1398px 733px #fff, 408px 1352px #fff,
    1774px 120px #fff, 1152px 1370px #fff, 1698px 1810px #fff, 710px 1450px #fff,
    665px 286px #fff, 493px 1720px #fff, 786px 5px #fff, 637px 1140px #fff,
    764px 324px #fff, 927px 310px #fff, 938px 1424px #fff, 1884px 744px #fff,
    913px 462px #fff, 1831px 1936px #fff, 1527px 249px #fff, 36px 1381px #fff,
    1597px 581px #fff, 1530px 355px #fff, 949px 459px #fff, 799px 828px #fff,
    242px 1471px #fff, 654px 797px #fff, 796px 594px #fff, 1365px 678px #fff,
    752px 23px #fff, 1630px 541px #fff, 982px 72px #fff, 1733px 1831px #fff,
    21px 412px #fff, 775px 998px #fff, 335px 1945px #fff, 264px 583px #fff,
    158px 1311px #fff, 528px 164px #fff, 1978px 574px #fff, 717px 1203px #fff,
    734px 1591px #fff, 1555px 820px #fff, 16px 1943px #fff, 1625px 1177px #fff,
    1236px 690px #fff, 1585px 1590px #fff, 1737px 1728px #fff, 721px 698px #fff,
    1804px 1186px #fff, 166px 980px #fff, 1850px 230px #fff, 330px 1712px #fff,
    95px 797px #fff, 1948px 1078px #fff, 469px 939px #fff, 1269px 1899px #fff,
    955px 1220px #fff, 1137px 1075px #fff, 312px 1293px #fff, 986px 1762px #fff,
    1103px 1238px #fff, 428px 1993px #fff, 355px 570px #fff, 977px 1836px #fff,
    1395px 1092px #fff, 276px 913px #fff, 1743px 656px #fff, 773px 502px #fff,
    1686px 1322px #fff, 1516px 1945px #fff, 1334px 501px #fff, 266px 156px #fff,
    455px 655px #fff, 798px 72px #fff, 1059px 1259px #fff, 1402px 1687px #fff,
    236px 1329px #fff, 1455px 786px #fff, 146px 1228px #fff, 1851px 823px #fff,
    1062px 100px #fff, 1220px 953px #fff, 20px 1826px #fff, 36px 1063px #fff,
    1525px 338px #fff, 790px 1521px #fff, 741px 1099px #fff, 288px 1489px #fff,
    700px 1060px #fff, 390px 1071px #fff, 411px 1036px #fff, 1853px 1072px #fff,
    1446px 1085px #fff, 1164px 874px #fff, 924px 925px #fff, 291px 271px #fff,
    1257px 1964px #fff, 1580px 1352px #fff, 1507px 1216px #fff, 211px 956px #fff,
    985px 1195px #fff, 975px 1640px #fff, 518px 101px #fff, 663px 1395px #fff,
    914px 532px #fff, 145px 1320px #fff, 69px 1397px #fff, 982px 523px #fff,
    257px 725px #fff, 1599px 831px #fff, 1636px 1513px #fff, 1250px 1158px #fff,
    1132px 604px #fff, 183px 102px #fff, 1057px 318px #fff, 1247px 1835px #fff,
    1983px 1110px #fff, 1077px 1455px #fff, 921px 1770px #fff, 806px 1350px #fff,
    1938px 1992px #fff, 855px 1260px #fff, 902px 1345px #fff, 658px 1908px #fff,
    1845px 679px #fff, 712px 1482px #fff, 595px 950px #fff, 1784px 1992px #fff,
    1847px 1785px #fff, 691px 1004px #fff, 175px 1179px #fff, 1666px 1911px #fff,
    41px 61px #fff, 971px 1080px #fff, 1830px 1450px #fff, 1351px 1518px #fff,
    1257px 99px #fff, 1395px 1498px #fff, 1117px 252px #fff, 1779px 597px #fff,
    1346px 729px #fff, 1108px 1144px #fff, 402px 691px #fff, 72px 496px #fff,
    1673px 1604px #fff, 1497px 974px #fff, 1865px 1664px #fff, 88px 806px #fff,
    918px 77px #fff, 244px 1118px #fff, 256px 1820px #fff, 1851px 1840px #fff,
    605px 1851px #fff, 634px 383px #fff, 865px 37px #fff, 943px 1024px #fff,
    1951px 177px #fff, 1097px 523px #fff, 985px 1700px #fff, 1243px 122px #fff,
    768px 1070px #fff, 468px 194px #fff, 320px 1867px #fff, 1850px 185px #fff,
    380px 1616px #fff, 468px 1294px #fff, 1122px 1743px #fff, 884px 299px #fff,
    1300px 1917px #fff, 1860px 396px #fff, 1270px 990px #fff, 529px 733px #fff,
    1975px 1347px #fff, 1885px 685px #fff, 226px 506px #fff, 651px 878px #fff,
    1323px 680px #fff, 1284px 680px #fff, 238px 1967px #fff, 911px 174px #fff,
    1111px 521px #fff, 1150px 85px #fff, 794px 502px #fff, 484px 1856px #fff,
    1809px 368px #fff, 112px 953px #fff, 590px 1009px #fff, 1655px 311px #fff,
    100px 1026px #fff, 1803px 352px #fff, 865px 306px #fff, 1077px 1019px #fff,
    1335px 872px #fff, 1647px 1298px #fff, 1233px 1387px #fff, 698px 1036px #fff,
    659px 1860px #fff, 388px 1412px #fff, 1212px 458px #fff, 755px 1468px #fff,
    696px 1654px #fff, 1144px 60px #fff;
}

#stars:after {
  content: "";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 779px 1331px #fff, 324px 42px #fff, 303px 586px #fff,
    1312px 276px #fff, 451px 625px #fff, 521px 1931px #fff, 1087px 1871px #fff,
    36px 1546px #fff, 132px 934px #fff, 1698px 901px #fff, 1418px 664px #fff,
    1448px 1157px #fff, 1084px 232px #fff, 347px 1776px #fff, 1722px 243px #fff,
    1629px 835px #fff, 479px 969px #fff, 1231px 960px #fff, 586px 384px #fff,
    164px 527px #fff, 8px 646px #fff, 1150px 1126px #fff, 665px 1357px #fff,
    1556px 1982px #fff, 1260px 1961px #fff, 1675px 1741px #fff,
    1843px 1514px #fff, 718px 1628px #fff, 242px 1343px #fff, 1497px 1880px #fff,
    1364px 230px #fff, 1739px 1302px #fff, 636px 959px #fff, 304px 686px #fff,
    614px 751px #fff, 1935px 816px #fff, 1428px 60px #fff, 355px 335px #fff,
    1594px 158px #fff, 90px 60px #fff, 1553px 162px #fff, 1239px 1825px #fff,
    1945px 587px #fff, 749px 1785px #fff, 1987px 1172px #fff, 1301px 1237px #fff,
    1039px 342px #fff, 1585px 1481px #fff, 995px 1048px #fff, 524px 932px #fff,
    214px 413px #fff, 1701px 1300px #fff, 1037px 1613px #fff, 1871px 996px #fff,
    1360px 1635px #fff, 1110px 1313px #fff, 412px 1783px #fff, 1949px 177px #fff,
    903px 1854px #fff, 700px 1936px #fff, 378px 125px #fff, 308px 834px #fff,
    1118px 962px #fff, 1350px 1929px #fff, 781px 1811px #fff, 561px 137px #fff,
    757px 1148px #fff, 1670px 1979px #fff, 343px 739px #fff, 945px 795px #fff,
    576px 1903px #fff, 1078px 1436px #fff, 1583px 450px #fff, 1366px 474px #fff,
    297px 1873px #fff, 192px 162px #fff, 1624px 1633px #fff, 59px 453px #fff,
    82px 1872px #fff, 1933px 498px #fff, 1966px 1974px #fff, 1975px 1688px #fff,
    779px 314px #fff, 1858px 1543px #fff, 73px 1507px #fff, 1693px 975px #fff,
    1683px 108px #fff, 1768px 1654px #fff, 654px 14px #fff, 494px 171px #fff,
    1689px 1895px #fff, 1660px 263px #fff, 1031px 903px #fff, 1203px 1393px #fff,
    1333px 1421px #fff, 1113px 41px #fff, 1206px 1645px #fff, 1325px 1635px #fff,
    142px 388px #fff, 572px 215px #fff, 1535px 296px #fff, 1419px 407px #fff,
    1379px 1003px #fff, 329px 469px #fff, 1791px 1652px #fff, 935px 1802px #fff,
    1330px 1820px #fff, 421px 1933px #fff, 828px 365px #fff, 275px 316px #fff,
    707px 960px #fff, 1605px 1554px #fff, 625px 58px #fff, 717px 1697px #fff,
    1669px 246px #fff, 1925px 322px #fff, 1154px 1803px #fff, 1929px 295px #fff,
    1248px 240px #fff, 1045px 1755px #fff, 166px 942px #fff, 1888px 1773px #fff,
    678px 1963px #fff, 1370px 569px #fff, 1974px 1400px #fff, 1786px 460px #fff,
    51px 307px #fff, 784px 1400px #fff, 730px 1258px #fff, 1712px 393px #fff,
    416px 170px #fff, 1797px 1932px #fff, 572px 219px #fff, 1557px 1856px #fff,
    218px 8px #fff, 348px 1334px #fff, 469px 413px #fff, 385px 1738px #fff,
    1357px 1818px #fff, 240px 942px #fff, 248px 1847px #fff, 1535px 806px #fff,
    236px 1514px #fff, 1429px 1556px #fff, 73px 1633px #fff, 1398px 1121px #fff,
    671px 1301px #fff, 1404px 1663px #fff, 740px 1018px #fff, 1600px 377px #fff,
    785px 514px #fff, 112px 1084px #fff, 1915px 1887px #fff, 1463px 1848px #fff,
    687px 1115px #fff, 1268px 1768px #fff, 1729px 1425px #fff,
    1284px 1022px #fff, 801px 974px #fff, 1975px 1317px #fff, 1354px 834px #fff,
    1446px 1484px #fff, 1283px 1786px #fff, 11px 523px #fff, 1842px 236px #fff,
    1355px 654px #fff, 429px 7px #fff, 1033px 1128px #fff, 157px 297px #fff,
    545px 635px #fff, 52px 1080px #fff, 827px 1520px #fff, 1121px 490px #fff,
    9px 309px #fff, 1744px 1586px #fff, 1014px 417px #fff, 1534px 524px #fff,
    958px 552px #fff, 1403px 1496px #fff, 387px 703px #fff, 1522px 548px #fff,
    1355px 282px #fff, 1532px 601px #fff, 1838px 790px #fff, 290px 259px #fff,
    295px 598px #fff, 1601px 539px #fff, 1561px 1272px #fff, 34px 1922px #fff,
    1024px 543px #fff, 467px 369px #fff, 722px 333px #fff, 1976px 1255px #fff,
    766px 983px #fff, 1582px 1285px #fff, 12px 512px #fff, 617px 1410px #fff,
    682px 577px #fff, 1334px 1438px #fff, 439px 327px #fff, 1617px 1661px #fff,
    673px 129px #fff, 794px 941px #fff, 1386px 1902px #fff, 37px 1353px #fff,
    1467px 1353px #fff, 416px 18px #fff, 187px 344px #fff, 200px 1898px #fff,
    1491px 1619px #fff, 811px 347px #fff, 924px 1827px #fff, 945px 217px #fff,
    1735px 1228px #fff, 379px 1890px #fff, 79px 761px #fff, 825px 1837px #fff,
    1980px 1558px #fff, 1308px 1573px #fff, 1488px 1726px #fff,
    382px 1208px #fff, 522px 595px #fff, 1277px 1898px #fff, 354px 552px #fff,
    161px 1784px #fff, 614px 251px #fff, 526px 1576px #fff, 17px 212px #fff,
    179px 996px #fff, 467px 1208px #fff, 1944px 1838px #fff, 1140px 1093px #fff,
    858px 1007px #fff, 200px 1064px #fff, 423px 1964px #fff, 1945px 439px #fff,
    1377px 689px #fff, 1120px 1437px #fff, 1876px 668px #fff, 907px 1324px #fff,
    343px 1976px #fff, 1816px 1501px #fff, 1849px 177px #fff, 647px 91px #fff,
    1984px 1012px #fff, 1336px 1300px #fff, 128px 648px #fff, 305px 1060px #fff,
    1324px 826px #fff, 1263px 1314px #fff, 1801px 629px #fff, 1614px 1555px #fff,
    1634px 90px #fff, 1603px 452px #fff, 891px 1984px #fff, 1556px 1906px #fff,
    121px 68px #fff, 1676px 1714px #fff, 516px 936px #fff, 1947px 1492px #fff,
    1455px 1519px #fff, 45px 602px #fff, 205px 1039px #fff, 793px 172px #fff,
    1562px 1739px #fff, 1056px 110px #fff, 1512px 379px #fff, 1795px 1621px #fff,
    1848px 607px #fff, 262px 1719px #fff, 477px 991px #fff, 483px 883px #fff,
    1239px 1197px #fff, 1496px 647px #fff, 1649px 25px #fff, 1491px 1946px #fff,
    119px 996px #fff, 179px 1472px #fff, 1341px 808px #fff, 1565px 1700px #fff,
    407px 1544px #fff, 1754px 357px #fff, 1288px 981px #fff, 902px 1997px #fff,
    1755px 1668px #fff, 186px 877px #fff, 1202px 1882px #fff, 461px 1213px #fff,
    1400px 748px #fff, 1969px 1899px #fff, 809px 522px #fff, 514px 1219px #fff,
    374px 275px #fff, 938px 1973px #fff, 357px 552px #fff, 144px 1722px #fff,
    1572px 912px #fff, 402px 1858px #fff, 1544px 1195px #fff, 667px 1257px #fff,
    727px 1496px #fff, 993px 232px #fff, 1772px 313px #fff, 1040px 1590px #fff,
    1204px 1973px #fff, 1268px 79px #fff, 1555px 1048px #fff, 986px 1707px #fff,
    978px 1710px #fff, 713px 360px #fff, 407px 863px #fff, 461px 736px #fff,
    284px 1608px #fff, 103px 430px #fff, 1283px 1319px #fff, 977px 1186px #fff,
    1966px 1516px #fff, 1287px 1129px #fff, 70px 1098px #fff, 1189px 889px #fff,
    1126px 1734px #fff, 309px 1292px #fff, 879px 764px #fff, 65px 473px #fff,
    1003px 1959px #fff, 658px 791px #fff, 402px 1576px #fff, 35px 622px #fff,
    529px 1589px #fff, 164px 666px #fff, 1876px 1290px #fff, 1541px 526px #fff,
    270px 1297px #fff, 440px 865px #fff, 1500px 802px #fff, 182px 1754px #fff,
    1264px 892px #fff, 272px 1249px #fff, 1289px 1535px #fff, 190px 1646px #fff,
    955px 242px #fff, 1456px 1597px #fff, 1727px 1983px #fff, 635px 801px #fff,
    226px 455px #fff, 1396px 1710px #fff, 849px 1863px #fff, 237px 1264px #fff,
    839px 140px #fff, 1122px 735px #fff, 1280px 15px #fff, 1318px 242px #fff,
    1819px 1148px #fff, 333px 1392px #fff, 1949px 553px #fff, 1878px 1332px #fff,
    467px 548px #fff, 1812px 1082px #fff, 1067px 193px #fff, 243px 156px #fff,
    483px 1616px #fff, 1714px 933px #fff, 759px 1800px #fff, 1822px 995px #fff,
    1877px 572px #fff, 581px 1084px #fff, 107px 732px #fff, 642px 1837px #fff,
    166px 1493px #fff, 1555px 198px #fff, 819px 307px #fff, 947px 345px #fff,
    827px 224px #fff, 927px 1394px #fff, 540px 467px #fff, 1093px 405px #fff,
    1140px 927px #fff, 130px 529px #fff, 33px 1980px #fff, 1147px 1663px #fff,
    1616px 1436px #fff, 528px 710px #fff, 798px 1100px #fff, 505px 1480px #fff,
    899px 641px #fff, 1909px 1949px #fff, 1311px 964px #fff, 979px 1301px #fff,
    1393px 969px #fff, 1793px 1886px #fff, 292px 357px #fff, 1196px 1718px #fff,
    1290px 1994px #fff, 537px 1973px #fff, 1181px 1674px #fff,
    1740px 1566px #fff, 1307px 265px #fff, 922px 522px #fff, 1892px 472px #fff,
    384px 1746px #fff, 392px 1098px #fff, 647px 548px #fff, 390px 1498px #fff,
    1246px 138px #fff, 730px 876px #fff, 192px 1472px #fff, 1790px 1789px #fff,
    928px 311px #fff, 1253px 1647px #fff, 747px 1921px #fff, 1561px 1025px #fff,
    1533px 1292px #fff, 1985px 195px #fff, 728px 729px #fff, 1712px 1936px #fff,
    512px 1717px #fff, 1528px 483px #fff, 313px 1642px #fff, 281px 1849px #fff,
    1212px 799px #fff, 435px 1191px #fff, 1422px 611px #fff, 1718px 1964px #fff,
    411px 944px #fff, 210px 636px #fff, 1502px 1295px #fff, 1434px 349px #fff,
    769px 60px #fff, 747px 1053px #fff, 789px 504px #fff, 1436px 1264px #fff,
    1893px 1225px #fff, 1394px 1788px #fff, 1108px 1317px #fff,
    1673px 1395px #fff, 854px 1010px #fff, 1705px 80px #fff, 1858px 148px #fff,
    1729px 344px #fff, 1388px 664px #fff, 895px 406px #fff, 1479px 157px #fff,
    1441px 1157px #fff, 552px 1900px #fff, 516px 364px #fff, 1647px 189px #fff,
    1427px 1071px #fff, 785px 729px #fff, 1080px 1710px #fff, 504px 204px #fff,
    1177px 1622px #fff, 657px 34px #fff, 1296px 1099px #fff, 248px 180px #fff,
    1212px 1568px #fff, 667px 1562px #fff, 695px 841px #fff, 1608px 1247px #fff,
    751px 882px #fff, 87px 167px #fff, 607px 1368px #fff, 1363px 1203px #fff,
    1836px 317px #fff, 1668px 1703px #fff, 830px 1154px #fff, 1721px 1398px #fff,
    1601px 1280px #fff, 976px 874px #fff, 1743px 254px #fff, 1020px 1815px #fff,
    1670px 1766px #fff, 1890px 735px #fff, 1379px 136px #fff, 1864px 695px #fff,
    206px 965px #fff, 1404px 1932px #fff, 1923px 1360px #fff, 247px 682px #fff,
    519px 1708px #fff, 645px 750px #fff, 1164px 1204px #fff, 834px 323px #fff,
    172px 1350px #fff, 213px 972px #fff, 1837px 190px #fff, 285px 1806px #fff,
    1047px 1299px #fff, 1548px 825px #fff, 1730px 324px #fff, 1346px 1909px #fff,
    772px 270px #fff, 345px 1190px #fff, 478px 1433px #fff, 1479px 25px #fff,
    1994px 1830px #fff, 1744px 732px #fff, 20px 1635px #fff, 690px 1795px #fff,
    1594px 569px #fff, 579px 245px #fff, 1398px 733px #fff, 408px 1352px #fff,
    1774px 120px #fff, 1152px 1370px #fff, 1698px 1810px #fff, 710px 1450px #fff,
    665px 286px #fff, 493px 1720px #fff, 786px 5px #fff, 637px 1140px #fff,
    764px 324px #fff, 927px 310px #fff, 938px 1424px #fff, 1884px 744px #fff,
    913px 462px #fff, 1831px 1936px #fff, 1527px 249px #fff, 36px 1381px #fff,
    1597px 581px #fff, 1530px 355px #fff, 949px 459px #fff, 799px 828px #fff,
    242px 1471px #fff, 654px 797px #fff, 796px 594px #fff, 1365px 678px #fff,
    752px 23px #fff, 1630px 541px #fff, 982px 72px #fff, 1733px 1831px #fff,
    21px 412px #fff, 775px 998px #fff, 335px 1945px #fff, 264px 583px #fff,
    158px 1311px #fff, 528px 164px #fff, 1978px 574px #fff, 717px 1203px #fff,
    734px 1591px #fff, 1555px 820px #fff, 16px 1943px #fff, 1625px 1177px #fff,
    1236px 690px #fff, 1585px 1590px #fff, 1737px 1728px #fff, 721px 698px #fff,
    1804px 1186px #fff, 166px 980px #fff, 1850px 230px #fff, 330px 1712px #fff,
    95px 797px #fff, 1948px 1078px #fff, 469px 939px #fff, 1269px 1899px #fff,
    955px 1220px #fff, 1137px 1075px #fff, 312px 1293px #fff, 986px 1762px #fff,
    1103px 1238px #fff, 428px 1993px #fff, 355px 570px #fff, 977px 1836px #fff,
    1395px 1092px #fff, 276px 913px #fff, 1743px 656px #fff, 773px 502px #fff,
    1686px 1322px #fff, 1516px 1945px #fff, 1334px 501px #fff, 266px 156px #fff,
    455px 655px #fff, 798px 72px #fff, 1059px 1259px #fff, 1402px 1687px #fff,
    236px 1329px #fff, 1455px 786px #fff, 146px 1228px #fff, 1851px 823px #fff,
    1062px 100px #fff, 1220px 953px #fff, 20px 1826px #fff, 36px 1063px #fff,
    1525px 338px #fff, 790px 1521px #fff, 741px 1099px #fff, 288px 1489px #fff,
    700px 1060px #fff, 390px 1071px #fff, 411px 1036px #fff, 1853px 1072px #fff,
    1446px 1085px #fff, 1164px 874px #fff, 924px 925px #fff, 291px 271px #fff,
    1257px 1964px #fff, 1580px 1352px #fff, 1507px 1216px #fff, 211px 956px #fff,
    985px 1195px #fff, 975px 1640px #fff, 518px 101px #fff, 663px 1395px #fff,
    914px 532px #fff, 145px 1320px #fff, 69px 1397px #fff, 982px 523px #fff,
    257px 725px #fff, 1599px 831px #fff, 1636px 1513px #fff, 1250px 1158px #fff,
    1132px 604px #fff, 183px 102px #fff, 1057px 318px #fff, 1247px 1835px #fff,
    1983px 1110px #fff, 1077px 1455px #fff, 921px 1770px #fff, 806px 1350px #fff,
    1938px 1992px #fff, 855px 1260px #fff, 902px 1345px #fff, 658px 1908px #fff,
    1845px 679px #fff, 712px 1482px #fff, 595px 950px #fff, 1784px 1992px #fff,
    1847px 1785px #fff, 691px 1004px #fff, 175px 1179px #fff, 1666px 1911px #fff,
    41px 61px #fff, 971px 1080px #fff, 1830px 1450px #fff, 1351px 1518px #fff,
    1257px 99px #fff, 1395px 1498px #fff, 1117px 252px #fff, 1779px 597px #fff,
    1346px 729px #fff, 1108px 1144px #fff, 402px 691px #fff, 72px 496px #fff,
    1673px 1604px #fff, 1497px 974px #fff, 1865px 1664px #fff, 88px 806px #fff,
    918px 77px #fff, 244px 1118px #fff, 256px 1820px #fff, 1851px 1840px #fff,
    605px 1851px #fff, 634px 383px #fff, 865px 37px #fff, 943px 1024px #fff,
    1951px 177px #fff, 1097px 523px #fff, 985px 1700px #fff, 1243px 122px #fff,
    768px 1070px #fff, 468px 194px #fff, 320px 1867px #fff, 1850px 185px #fff,
    380px 1616px #fff, 468px 1294px #fff, 1122px 1743px #fff, 884px 299px #fff,
    1300px 1917px #fff, 1860px 396px #fff, 1270px 990px #fff, 529px 733px #fff,
    1975px 1347px #fff, 1885px 685px #fff, 226px 506px #fff, 651px 878px #fff,
    1323px 680px #fff, 1284px 680px #fff, 238px 1967px #fff, 911px 174px #fff,
    1111px 521px #fff, 1150px 85px #fff, 794px 502px #fff, 484px 1856px #fff,
    1809px 368px #fff, 112px 953px #fff, 590px 1009px #fff, 1655px 311px #fff,
    100px 1026px #fff, 1803px 352px #fff, 865px 306px #fff, 1077px 1019px #fff,
    1335px 872px #fff, 1647px 1298px #fff, 1233px 1387px #fff, 698px 1036px #fff,
    659px 1860px #fff, 388px 1412px #fff, 1212px 458px #fff, 755px 1468px #fff,
    696px 1654px #fff, 1144px 60px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  animation: animStar 100s linear infinite;
  box-shadow: 1448px 320px #fff, 1775px 1663px #fff, 332px 1364px #fff,
    878px 340px #fff, 569px 1832px #fff, 1422px 1684px #fff, 1946px 1907px #fff,
    121px 979px #fff, 1044px 1069px #fff, 463px 381px #fff, 423px 112px #fff,
    523px 1179px #fff, 779px 654px #fff, 1398px 694px #fff, 1085px 1464px #fff,
    1599px 1869px #fff, 801px 1882px #fff, 779px 1231px #fff, 552px 932px #fff,
    1057px 1196px #fff, 282px 1280px #fff, 496px 1986px #fff, 1833px 1120px #fff,
    1802px 1293px #fff, 6px 1696px #fff, 412px 1902px #fff, 605px 438px #fff,
    24px 1212px #fff, 234px 1320px #fff, 544px 344px #fff, 1107px 170px #fff,
    1603px 196px #fff, 905px 648px #fff, 68px 1458px #fff, 649px 1969px #fff,
    744px 675px #fff, 1127px 478px #fff, 714px 1814px #fff, 1486px 526px #fff,
    270px 1636px #fff, 1931px 149px #fff, 1807px 378px #fff, 8px 390px #fff,
    1415px 699px #fff, 1473px 1211px #fff, 1590px 141px #fff, 270px 1705px #fff,
    69px 1423px #fff, 1108px 1053px #fff, 1946px 128px #fff, 371px 371px #fff,
    1490px 220px #fff, 357px 1885px #fff, 363px 363px #fff, 1896px 1256px #fff,
    1979px 1050px #fff, 947px 1342px #fff, 1754px 242px #fff, 514px 974px #fff,
    65px 1477px #fff, 1840px 547px #fff, 950px 695px #fff, 459px 1150px #fff,
    1124px 1502px #fff, 481px 940px #fff, 680px 839px #fff, 797px 1169px #fff,
    1977px 1491px #fff, 734px 1724px #fff, 210px 298px #fff, 816px 628px #fff,
    686px 770px #fff, 1721px 267px #fff, 1663px 511px #fff, 1481px 1141px #fff,
    582px 248px #fff, 1308px 953px #fff, 628px 657px #fff, 897px 1535px #fff,
    270px 931px #fff, 791px 467px #fff, 1336px 1732px #fff, 1013px 1653px #fff,
    1911px 956px #fff, 587px 816px #fff, 83px 456px #fff, 930px 1478px #fff,
    1587px 1694px #fff, 614px 1200px #fff, 302px 1782px #fff, 1711px 1432px #fff,
    443px 904px #fff, 1666px 714px #fff, 1588px 1167px #fff, 273px 1075px #fff,
    1679px 461px #fff, 721px 664px #fff, 1202px 10px #fff, 166px 1126px #fff,
    331px 1628px #fff, 430px 1565px #fff, 1585px 509px #fff, 640px 38px #fff,
    822px 837px #fff, 1760px 1664px #fff, 1122px 1458px #fff, 398px 131px #fff,
    689px 285px #fff, 460px 652px #fff, 1627px 365px #fff, 348px 1648px #fff,
    819px 1946px #fff, 981px 1917px #fff, 323px 76px #fff, 979px 684px #fff,
    887px 536px #fff, 1348px 1596px #fff, 1055px 666px #fff, 1402px 1797px #fff,
    1300px 1055px #fff, 937px 238px #fff, 1474px 1815px #fff, 1144px 1710px #fff,
    1629px 1087px #fff, 911px 919px #fff, 771px 819px #fff, 403px 720px #fff,
    163px 736px #fff, 1062px 238px #fff, 1774px 818px #fff, 1874px 1178px #fff,
    1177px 699px #fff, 1244px 1244px #fff, 1371px 58px #fff, 564px 1515px #fff,
    1824px 487px #fff, 929px 702px #fff, 394px 1348px #fff, 1161px 641px #fff,
    219px 1841px #fff, 358px 941px #fff, 140px 1759px #fff, 1019px 1345px #fff,
    274px 436px #fff, 1433px 1605px #fff, 1798px 1426px #fff, 294px 1848px #fff,
    1681px 1877px #fff, 1344px 1824px #fff, 1439px 1632px #fff,
    161px 1012px #fff, 1308px 588px #fff, 1789px 582px #fff, 721px 1910px #fff,
    318px 218px #fff, 607px 319px #fff, 495px 535px #fff, 1552px 1575px #fff,
    1562px 67px #fff, 403px 926px #fff, 1096px 1800px #fff, 1814px 1709px #fff,
    1882px 1831px #fff, 533px 46px #fff, 823px 969px #fff, 530px 165px #fff,
    1030px 352px #fff, 1681px 313px #fff, 338px 115px #fff, 1607px 211px #fff,
    1718px 1184px #fff, 1589px 659px #fff, 278px 355px #fff, 464px 1464px #fff,
    1165px 277px #fff, 950px 694px #fff, 1746px 293px #fff, 793px 911px #fff,
    528px 773px #fff, 1883px 1694px #fff, 748px 182px #fff, 1924px 1531px #fff,
    100px 636px #fff, 1473px 1445px #fff, 1264px 1244px #fff, 850px 1377px #fff,
    987px 1976px #fff, 933px 1761px #fff, 922px 1270px #fff, 500px 396px #fff,
    1324px 8px #fff, 1967px 1814px #fff, 1072px 1401px #fff, 961px 37px #fff,
    156px 81px #fff, 1915px 502px #fff, 1076px 1846px #fff, 152px 1669px #fff,
    986px 1529px #fff, 1667px 1137px #fff;
}

#stars2:after {
  content: "";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1448px 320px #fff, 1775px 1663px #fff, 332px 1364px #fff,
    878px 340px #fff, 569px 1832px #fff, 1422px 1684px #fff, 1946px 1907px #fff,
    121px 979px #fff, 1044px 1069px #fff, 463px 381px #fff, 423px 112px #fff,
    523px 1179px #fff, 779px 654px #fff, 1398px 694px #fff, 1085px 1464px #fff,
    1599px 1869px #fff, 801px 1882px #fff, 779px 1231px #fff, 552px 932px #fff,
    1057px 1196px #fff, 282px 1280px #fff, 496px 1986px #fff, 1833px 1120px #fff,
    1802px 1293px #fff, 6px 1696px #fff, 412px 1902px #fff, 605px 438px #fff,
    24px 1212px #fff, 234px 1320px #fff, 544px 344px #fff, 1107px 170px #fff,
    1603px 196px #fff, 905px 648px #fff, 68px 1458px #fff, 649px 1969px #fff,
    744px 675px #fff, 1127px 478px #fff, 714px 1814px #fff, 1486px 526px #fff,
    270px 1636px #fff, 1931px 149px #fff, 1807px 378px #fff, 8px 390px #fff,
    1415px 699px #fff, 1473px 1211px #fff, 1590px 141px #fff, 270px 1705px #fff,
    69px 1423px #fff, 1108px 1053px #fff, 1946px 128px #fff, 371px 371px #fff,
    1490px 220px #fff, 357px 1885px #fff, 363px 363px #fff, 1896px 1256px #fff,
    1979px 1050px #fff, 947px 1342px #fff, 1754px 242px #fff, 514px 974px #fff,
    65px 1477px #fff, 1840px 547px #fff, 950px 695px #fff, 459px 1150px #fff,
    1124px 1502px #fff, 481px 940px #fff, 680px 839px #fff, 797px 1169px #fff,
    1977px 1491px #fff, 734px 1724px #fff, 210px 298px #fff, 816px 628px #fff,
    686px 770px #fff, 1721px 267px #fff, 1663px 511px #fff, 1481px 1141px #fff,
    582px 248px #fff, 1308px 953px #fff, 628px 657px #fff, 897px 1535px #fff,
    270px 931px #fff, 791px 467px #fff, 1336px 1732px #fff, 1013px 1653px #fff,
    1911px 956px #fff, 587px 816px #fff, 83px 456px #fff, 930px 1478px #fff,
    1587px 1694px #fff, 614px 1200px #fff, 302px 1782px #fff, 1711px 1432px #fff,
    443px 904px #fff, 1666px 714px #fff, 1588px 1167px #fff, 273px 1075px #fff,
    1679px 461px #fff, 721px 664px #fff, 1202px 10px #fff, 166px 1126px #fff,
    331px 1628px #fff, 430px 1565px #fff, 1585px 509px #fff, 640px 38px #fff,
    822px 837px #fff, 1760px 1664px #fff, 1122px 1458px #fff, 398px 131px #fff,
    689px 285px #fff, 460px 652px #fff, 1627px 365px #fff, 348px 1648px #fff,
    819px 1946px #fff, 981px 1917px #fff, 323px 76px #fff, 979px 684px #fff,
    887px 536px #fff, 1348px 1596px #fff, 1055px 666px #fff, 1402px 1797px #fff,
    1300px 1055px #fff, 937px 238px #fff, 1474px 1815px #fff, 1144px 1710px #fff,
    1629px 1087px #fff, 911px 919px #fff, 771px 819px #fff, 403px 720px #fff,
    163px 736px #fff, 1062px 238px #fff, 1774px 818px #fff, 1874px 1178px #fff,
    1177px 699px #fff, 1244px 1244px #fff, 1371px 58px #fff, 564px 1515px #fff,
    1824px 487px #fff, 929px 702px #fff, 394px 1348px #fff, 1161px 641px #fff,
    219px 1841px #fff, 358px 941px #fff, 140px 1759px #fff, 1019px 1345px #fff,
    274px 436px #fff, 1433px 1605px #fff, 1798px 1426px #fff, 294px 1848px #fff,
    1681px 1877px #fff, 1344px 1824px #fff, 1439px 1632px #fff,
    161px 1012px #fff, 1308px 588px #fff, 1789px 582px #fff, 721px 1910px #fff,
    318px 218px #fff, 607px 319px #fff, 495px 535px #fff, 1552px 1575px #fff,
    1562px 67px #fff, 403px 926px #fff, 1096px 1800px #fff, 1814px 1709px #fff,
    1882px 1831px #fff, 533px 46px #fff, 823px 969px #fff, 530px 165px #fff,
    1030px 352px #fff, 1681px 313px #fff, 338px 115px #fff, 1607px 211px #fff,
    1718px 1184px #fff, 1589px 659px #fff, 278px 355px #fff, 464px 1464px #fff,
    1165px 277px #fff, 950px 694px #fff, 1746px 293px #fff, 793px 911px #fff,
    528px 773px #fff, 1883px 1694px #fff, 748px 182px #fff, 1924px 1531px #fff,
    100px 636px #fff, 1473px 1445px #fff, 1264px 1244px #fff, 850px 1377px #fff,
    987px 1976px #fff, 933px 1761px #fff, 922px 1270px #fff, 500px 396px #fff,
    1324px 8px #fff, 1967px 1814px #fff, 1072px 1401px #fff, 961px 37px #fff,
    156px 81px #fff, 1915px 502px #fff, 1076px 1846px #fff, 152px 1669px #fff,
    986px 1529px #fff, 1667px 1137px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  animation: animStar 150s linear infinte;
  box-shadow: 387px 1878px #fff, 760px 1564px #fff, 1487px 999px #fff,
    948px 1828px #fff, 1977px 1001px #fff, 1284px 1963px #fff, 656px 284px #fff,
    1268px 1635px #fff, 1820px 598px #fff, 642px 1900px #fff, 296px 57px #fff,
    921px 1620px #fff, 476px 1858px #fff, 658px 613px #fff, 1171px 1363px #fff,
    1419px 283px #fff, 1037px 731px #fff, 503px 663px #fff, 1562px 463px #fff,
    383px 1197px #fff, 1171px 1233px #fff, 876px 1768px #fff, 856px 1615px #fff,
    1375px 1924px #fff, 1725px 918px #fff, 952px 119px #fff, 768px 1212px #fff,
    992px 1462px #fff, 1929px 717px #fff, 1947px 755px #fff, 1818px 1123px #fff,
    1896px 1672px #fff, 460px 198px #fff, 256px 271px #fff, 752px 544px #fff,
    1222px 1859px #fff, 1851px 443px #fff, 313px 1858px #fff, 709px 446px #fff,
    1546px 697px #fff, 674px 1155px #fff, 1112px 130px #fff, 355px 1790px #fff,
    1496px 974px #fff, 1696px 480px #fff, 1316px 1265px #fff, 1645px 1063px #fff,
    1182px 237px #fff, 427px 1582px #fff, 859px 253px #fff, 458px 939px #fff,
    1517px 1644px #fff, 1943px 60px #fff, 212px 1650px #fff, 966px 1786px #fff,
    473px 712px #fff, 130px 76px #fff, 1417px 1186px #fff, 909px 1580px #fff,
    1913px 762px #fff, 204px 1143px #fff, 1998px 1057px #fff, 1468px 1301px #fff,
    144px 1676px #fff, 21px 1601px #fff, 382px 1362px #fff, 912px 753px #fff,
    1488px 1405px #fff, 802px 156px #fff, 174px 550px #fff, 338px 1366px #fff,
    1197px 774px #fff, 602px 486px #fff, 682px 1877px #fff, 348px 1503px #fff,
    407px 1139px #fff, 950px 1400px #fff, 922px 1139px #fff, 1697px 293px #fff,
    1238px 1281px #fff, 1038px 1197px #fff, 376px 1889px #fff,
    1255px 1680px #fff, 1008px 1316px #fff, 1538px 1447px #fff,
    1186px 874px #fff, 1967px 640px #fff, 1341px 19px #fff, 29px 1732px #fff,
    16px 1650px #fff, 1021px 1075px #fff, 723px 424px #fff, 1175px 41px #fff,
    494px 1957px #fff, 1296px 431px #fff, 175px 1507px #fff, 831px 121px #fff,
    498px 1947px #fff, 617px 880px #fff, 240px 403px #fff;
}

#stars3:after {
  content: "";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 387px 1878px #fff, 760px 1564px #fff, 1487px 999px #fff,
    948px 1828px #fff, 1977px 1001px #fff, 1284px 1963px #fff, 656px 284px #fff,
    1268px 1635px #fff, 1820px 598px #fff, 642px 1900px #fff, 296px 57px #fff,
    921px 1620px #fff, 476px 1858px #fff, 658px 613px #fff, 1171px 1363px #fff,
    1419px 283px #fff, 1037px 731px #fff, 503px 663px #fff, 1562px 463px #fff,
    383px 1197px #fff, 1171px 1233px #fff, 876px 1768px #fff, 856px 1615px #fff,
    1375px 1924px #fff, 1725px 918px #fff, 952px 119px #fff, 768px 1212px #fff,
    992px 1462px #fff, 1929px 717px #fff, 1947px 755px #fff, 1818px 1123px #fff,
    1896px 1672px #fff, 460px 198px #fff, 256px 271px #fff, 752px 544px #fff,
    1222px 1859px #fff, 1851px 443px #fff, 313px 1858px #fff, 709px 446px #fff,
    1546px 697px #fff, 674px 1155px #fff, 1112px 130px #fff, 355px 1790px #fff,
    1496px 974px #fff, 1696px 480px #fff, 1316px 1265px #fff, 1645px 1063px #fff,
    1182px 237px #fff, 427px 1582px #fff, 859px 253px #fff, 458px 939px #fff,
    1517px 1644px #fff, 1943px 60px #fff, 212px 1650px #fff, 966px 1786px #fff,
    473px 712px #fff, 130px 76px #fff, 1417px 1186px #fff, 909px 1580px #fff,
    1913px 762px #fff, 204px 1143px #fff, 1998px 1057px #fff, 1468px 1301px #fff,
    144px 1676px #fff, 21px 1601px #fff, 382px 1362px #fff, 912px 753px #fff,
    1488px 1405px #fff, 802px 156px #fff, 174px 550px #fff, 338px 1366px #fff,
    1197px 774px #fff, 602px 486px #fff, 682px 1877px #fff, 348px 1503px #fff,
    407px 1139px #fff, 950px 1400px #fff, 922px 1139px #fff, 1697px 293px #fff,
    1238px 1281px #fff, 1038px 1197px #fff, 376px 1889px #fff,
    1255px 1680px #fff, 1008px 1316px #fff, 1538px 1447px #fff,
    1186px 874px #fff, 1967px 640px #fff, 1341px 19px #fff, 29px 1732px #fff,
    16px 1650px #fff, 1021px 1075px #fff, 723px 424px #fff, 1175px 41px #fff,
    494px 1957px #fff, 1296px 431px #fff, 175px 1507px #fff, 831px 121px #fff,
    498px 1947px #fff, 617px 880px #fff, 240px 403px #fff;
}

@keyframes animStar {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(180deg);
  }
}
.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

/* Animation class 2: Bounce */
.bounce {
  animation: bounce 0.5s ease-in-out;
}

/* Animation class 3: Rotate */
.rotate {
  animation: rotate 1s linear;
}

/* Animation class 4: Color change */
.color-change {
  animation: colorChange 1s ease-in-out infinite alternate;
}
/* Keyframes for animations */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes colorChange {
  to {
    background-color: #e74c3c;
  }
}
.button-slide {
  transition: transform 0.3s ease-in-out;
}

.button-slide:hover {
  transform: translateX(10px);
}
.button-scale {
  transition: transform 0.3s ease-in-out;
}

.button-scale:hover {
  transform: scale(1.1);
}
.button-color-change {
  transition: background-color 0.3s ease-in-out;
}

.button-color-change:hover {
  background-color: lightblue;
}
.button-rotate {
  transition: transform 0.3s ease-in-out;
}

.button-rotate:hover {
  transform: rotate(45deg);
}
/* JelloButton.css */
.jello-button {
  animation: jello 0.6s both;
  transform-origin: center;
  cursor: pointer;
}
.jello-button:hover {
  animation: jello 0.6s infinite;
  transform-origin: center;
  cursor: pointer;
}
@keyframes jello {
  0%,
  11.1%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
/* ShakeButton.css */
.shake-button {
  animation: shake 0.6s both;
  cursor: pointer;
}
.shake-button:hover {
  animation: shake 0.6s infinite;
  cursor: pointer;
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}
/* svg {
  color: "red";
} */
