.register {
    width: 100%;
    height: 100%;
    color: #3a3a43;
    font-size: 14px;
    line-height: 36px;
    text-indent: 16px;
    border-radius: 10px;
    border: 2px solid #e1e7f0;
}


.register:hover{
    border: 2px solid #4192DE;
}



.register:focus {
    border: 2px solid #4192DE;
    outline:none;
    color:black;

}