.section8 {
    width: 100%;
    margin: auto;
    height: 100vh;
    margin-top: 200px;
    /* position: relative; */
}
.slick-dots li button:before{
    position: absolute;
    content: '';
    width: 23px !important;
    height: 23px !important;
    border: solid 3px rgba(3, 127, 154, 1);
    border-radius: 50%;
    font-size: 10px !important;
    left: -2px;
}

.section8 h1 {
    width: 268px;
    height: 89px;
    font-family: Anek Devanagari;
    font-size: 52px;
    line-height: 88.69px;
    position: relative;
    left:244px;
    top: 180px;
    font-weight: 700;
}
.section8 .circle{
    width: 124px;
    height: 124px;
    border-radius: 50%;
    position: relative;
    left: 74%;
    top: -350px;
    box-shadow: 0px 4px 4px 0px rgba(215, 29, 140, 1);
    background-color: rgba(215, 29, 140, 1);
}
.section8 .rectangle{
    position: relative;
    left: 12%;
    z-index: -222;
    top: -520px;
    transform: rotate(-10deg);
}
.dots1{
    width:450px;
    height:100px;
    position: relative;
    left:45%;
    top:-600px;
    display:flex;
    justify-content:center;
    gap:10px
}


.card-slider{
    width: 991px;
    height: 300px;
    position: relative;
    left: 300px;
    top: -400px;
    margin-bottom: 25px;
    z-index: 1000;
}
.card-item{
    display: none;
}
@media screen and (max-width: 768px) {
    .section8 {
        height: 42vh;
        position: relative;
        top: 1400px;
    }
    .section8 h1 {
        width: 268px;
        height: 89px;
        font-family: Anek Devanagari;
        font-size: 52px;
        line-height: 88.69px;
        position: relative;
        left:0px;
        top: -405px;
        font-weight: 700;
        z-index: 22;
    }
    .section8 .circle ,.rectangle{
       display: none;
    }
    .image-shape{
     display: none;
    }
    .card-slider{
        width: 991px;
        height: 850px;
        position: relative;
        left: -20px;
        top: -450px;
        margin-bottom: 0;
        z-index: 1000;
        display: none;
    }
    .card-item{
        display: block;
    }
}