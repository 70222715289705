.section-card .product{
    width: 100%;
    display: flex;
    margin-left: 10px;
gap: 10px;
position: relative;
z-index: 55;
}
.section-card .product h3{
    font-size: 16px;
    font-weight: 400;
    font-family: Anek Devanagari;
    line-height: 20.29px;
}
.section-card .product span{
    color: rgba(135, 135, 135, 1);
    font-size: 16px;
    font-weight: 400;
    font-family: Anek Devanagari;
    margin-top: 5px;
}
.section-card .product p{
    font-size: 12px;
    font-weight: 400;
    font-family: Anek Devanagari;
    line-height: 16.47px;
    color: rgba(135, 135, 135, 1);
}