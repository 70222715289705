@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');




:root{
    --main-color:rgba(3, 127, 154, 1);
    --black:rgb(0,0,0,1)
}






*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none !important;
}
/* body{
    overflow: hidden;
} */
.nav-bar-main{
    width: 95%;
    margin: 0 auto;
}
.nav-bar{
    width: 100%;
    padding: 25px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-logo img{
    width: 254px;
    height: 92px;
}
.nav-links{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-links a{
    font-size: 15px;
    font-weight: 400;
    font-family: inter;
    line-height: 18.15px;
    color: var(--black);
}
.nav-links button{
    width: 105px;
    height: 34px;
    border-radius: 50px;
    outline: none;
    border: none;
    background-color: var(--main-color);
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    font-family: inter;
    line-height: 18.15px;
}
Link{
    color:#fff;
}
#bars{
    font-size: 28px;
    color: var(--main-color);
    display: none;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .nav-links{
        width: 100%;
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        position: absolute;
       left: -100%;
       background:linear-gradient(to left ,#0cd4db,rgba(51, 170, 103, 0.765));
       top: 150px;
       border-radius: 25px;
    transition: .3s linear;
    }
    .nav-links a{
        margin-bottom: 50px;
        color: #fff;
    }
    #bars{
        display: block;
    }
    .nav-links.active {
        left: 0%;
        z-index: 112222;
    }
    
}