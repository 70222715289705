@import url('https://fonts.googleapis.com/css2?family=Anek+Devanagari:wght@100..800&family=Inter:wght@100..900&display=swap');

.hero-section{
    width: 90%;
    margin: auto;
    /* height: 100vh; */
    margin-top: 0px;
}
.hero-text{
    width: 100%;
    height: 100%;
   
}
.hero-text h1{
    font-size: 52px;
    font-weight: 700;
font-family: Anek Devanagari;
line-height: 60px;
width: 464px;
height: 180px;
}
.hero-text p{
    font-size: 16px;
    font-weight: 300;
font-family: Anek Devanagari;
line-height: 27.5px;
width: 464px;
height: 81px;
}
.hero-text .btn1{
    width: 196px;
    height: 38px;
    border-radius: 5px;
    border: solid 1px rgba(206, 206, 206, 1);
    font-size: 16px;
    font-weight: 300;
    font-family: Anek Devanagari;
    /* line-height: 60px; */
    color: rgba(156, 156, 156, 1);
    box-shadow: 0  0 4px rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 1);
outline: none;
}
.hero-text .btn2{
    width: 125px;
    height: 37px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 400;
    font-family: Anek Devanagari;
    outline: none;
    border: none;
    color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 25px 1px rgba(3, 127, 154, 1);
    background-color: rgba(3, 127, 154, 1);
margin-left: 12px;
}
.hero-content{
    width: 100%;
    height: 100%;
    margin-left: 250px;
    
}
.hero-content .large-cart{
    width: 283px;
    height: 466px;
  border: solid 1px rgba(167, 146, 119, 1);
  border-radius: 30px;
  background:url(../../../../public/g11281.png),rgba(167, 146, 119, 1);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  left: 50px;
}
.hero-content .large-cart .inner-cart{
    width: 100%;
    height: 178px;
    background-color: #fff;
    border-radius: 30px;
    position: relative;
    z-index: 21;
    overflow: hidden;
}
.hero-content .large-cart .outer-cart .links-outer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  list-style: none;
  margin-top: 50px;
}

.hero-content .large-cart .outer-cart .links-outer li{
    width: 185px;
    height: 32px;
    background-color: rgba(255, 255, 255, 0.88);
    border-radius: 25px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    font-family: Anek Devanagari;
    line-height: 30.2px;
}
 .social{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: -40px;
    margin-left: 50px;
    top: -450px;
    left: 210px;

}
 .social i{
    padding: 8px 8px;
    background-color: #fff;
    margin-left: 20px;
    font-size: 25px;
    border-radius: 50%;
    box-shadow: 3px 4px 4px 1px rgba(0, 0, 0, 0.25);
}
.hero-content .large-cart .inner-cart .info{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.hero-content .large-cart .inner-cart::after{
    position: absolute;
    content: '';
    width: 80%;
    height: 100%;
    background-color: rgba(255, 230, 230, 1);
    border-radius: 26% 98% 39% 100% / 53% 25% 71% 100% ;
    z-index: -1;
    left: -10px;
    top:45px;
    transform: rotate(180deg);
}
.hero-content .large-cart .inner-cart .info h2{
    font-size: 14px;
    font-weight: 400;
    font-family: Anek Devanagari;
    margin-top: 8px;
}
.hero-content .large-cart .inner-cart .info p{
    font-size: 12px;
    font-weight: 200;
    font-family: Anek Devanagari;
}
.hero-content  .laptop2{
   position: relative;
   top: -680px;
   left: 43%;
   z-index: -1;
}
.hero-content .small-cart{
    width: 209px;
    height: 219px;
    border-radius: 30px;
    box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.25);
    background-color: rgb(255, 255, 255);
    position: relative;
    left: -30%;
    top: -350px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    z-index: 100;
}
.hero-content .small-cart img{
    width: 192px;
    height: 104px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
}
.hero-content .small-cart .dots{
    width: 100%;
    margin-block: 5px;
    display: flex;
    justify-content: center;
   gap: 5px;
}

.hero-content .small-cart .dots span{
    padding: 2px;
    border-radius: 50%;
    border: solid 1px #000;
}
.hero-content .small-cart p{
    width: 195px;
    height: 69px;
    font-size: 8px;
    font-weight: 400;
    font-family: Anek Devanagari;
    text-align: center;
}

/* media screen */
@media screen and (max-width: 768px) {
   
    .hero-content   {
        position: relative;
    left: -450px;
    top: 450px;
    }
}
