.theme-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

#theme-white,
#theme-orange,
#theme-green,
#theme-red,
#theme-pink,
#theme-Purple,
#theme-brown,
#theme-blue,
#theme-black,
#theme-gray {
  margin: 5px 5px;
  padding: 10px;
  font-size: 0.5rem;
  border-radius: 5px;
  width: 8rem;
  height: 8rem;
  cursor: pointer;
}

#theme-white {
  background: white;
  border: solid gray 2px;
}

#theme-orange {
  background: orange;
}

#theme-green {
  background: green;
}

#theme-pink {
  background: pink;
}
#theme-Purple {
  background: Purple;
}
#theme-brown {
  background: brown;
}
#theme-blue {
  background: blue;
}
#theme-pink {
  background: pink;
}
#theme-black {
  background: black;
}
#theme-gray {
  background: gray;
}

// Import any SCSS variables or mixins here

.template1 {
  background-image: url("assets/Image/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  color: #33f8fe;

  &::-webkit-scrollbar {
    display: none;
  }

  .container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    width: 45%;
  }

  .header {
    text-align: center;

    .avatar {
      border-radius: 50%;
      max-width: 100px;
      max-height: 100px;
      border: 1px solid #f2f2f2;
      border-width: 2px;
      margin-bottom: 1%;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .template1-btn {
      color: #33f8fe;
      border-radius: 10px;
      border: 1px solid #33f8fe;
      width: 100;
      padding: 2%;
      background-color: #3423d9;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .contact1 {
    background-color: #3423d9;
    border-radius: 10px;
    color: #33f8fe;
    border: 1px solid #33f8fe;
    padding: 2%;

    .input1 {
      border: 1px solid #33f8fe;
      border-radius: 10px;
      background-color: transparent;
      outline: none;
      width: 100%;
      padding: 2%;
      margin: 1% 0;

      &::placeholder {
        color: #33f8fe;
      }
    }

    .submitbtn1 {
      border: 1px solid #33f8fe;
      border-radius: 10px;
      background-color: #33f8fe;
      color: #3423d9;
      padding: 2%;
      width: 100%;
    }
  }

  .details1 {
    width: 70%;
    margin: 2% auto;
    text-align: center;
    font-size: smaller;
    font-weight: bold;
    color: #33f8fe;
  }
}
.scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  display: block;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
