.section4{
    width: 80%;
    margin: auto;
    height: 48vh;
    position: relative;
    margin-top: 150px;

    
}
.section4 .blue ,.white ,.beige{
    width: 319px;
    height: 490px;
    transform: rotate(-180deg);
    border-radius: 20px;
    position: absolute;
}
.blue{
    background-color: rgba(16, 44, 87, 1);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    left: 150px;
    top: -50px;
}
.white{
    background-color: rgba(248, 246, 227, 1);
    box-shadow: 5px 4px 55px 0 rgba(255, 255, 255, 0.25);
    left: 80px;
}
.beige{
    background-color: rgba(218, 192, 163, 1);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
    top: 50px;
}
.section4-text{
    position: relative;
    left: 200px;
}
.section4-text h1{
    width: 650px;
    height: 180px;
    font-size: 52px;
    font-weight: 700;
    font-family: Anek Devanagari;
    line-height: 60px;
}
.section4-text button{
    width: 125px;
    height: 37px;
    background-color: var(--main-color);
    border: none;
    color: #fff;
    outline: none;
    border-radius: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    position: absolute;
    right: -250px;
}
/* media screen */
@media screen and (max-width: 768px) {
    .section4{
        position: relative;
        left: -250px;
    }
    .section4-text h1{
        width: 350px;
        height: 180px;
        font-size: 32px;
        font-weight: 700;
        font-family: Anek Devanagari;
        line-height: 60px;
    }
}