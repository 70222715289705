.footer{
    width: 100%;
    height: 529px;
    background-color: rgba(3, 127, 154, 1);
    margin-top: 250px;
}
.all-footer {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    gap: 60px;
}
.all-footer .first{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.all-footer .first img{
    width:254px ;
    height: 92px;
    margin-bottom: 25px;
}
.all-footer .text{
    width: 452px;
    height: 160px;
    font-family: Anek Devanagari;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
}
.social-footer{
    width: 287px;
    height: 33px;
color: transparent;
display: flex;
justify-content: center;
gap: 10px;
}
.social-footer i{
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    font-size: 30px;
}
.all-footer .two{
    width: 110px;
    height: 240px;
    margin-top: 40px;
}
.all-footer .three{
    width: 197px;
    height: 180px;
    margin-top: 40px;

}
.all-footer .four{
    width: 246px;
    height: 180px;
    margin-top: 40px;
}
.all-footer  h2{
    font-size: 25px;
    font-weight: 700;
    line-height: 60px;
    font-family: Anek Devanagari;
    color: #fff;

}
.all-footer .arrow{
    width: 50px;
    height: 50px;
    margin-top: 40px;
    cursor: pointer;
}
.footer .copyRight{
    text-align: center;
    margin-top: 80px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    font-family: Anek Devanagari;

}
@media screen and (max-width: 768px) {

    .footer {
        position: relative;
        top: 1200px;
        height: 270vh;
    }
    .all-footer {
       flex-direction: column;
    }
    .all-footer .text{
        width: 332px;
      
        margin-bottom: 12px;
    }
}