.card{
    width: 910px;
    height: 282px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  left: -70px;
}
.info-card{
  /* text-align: center; */
    width: 450px;
    position: absolute;
    left: 40%;
    top: 20px;
}
.info-card h2{
    width: 260px;
    height: 43px;
    font-size: 25px;
    font-weight: 700;
    font-family: Anek Devanagari;
    line-height: 42.64px;

}
.info-card p{
    width: 226px;
    height: 34px;
    font-size: 20px;
    font-weight: 500;
    font-family: Anek Devanagari;
    line-height: 12.64px;
    color: rgba(172, 172, 172, 1);
    
}
.info-card h3{
    width: 524px;
    height: 102px;
    font-size: 30px;
    font-weight: 500;
    font-family: Anek Devanagari;
    line-height: 51.17px;

}
@media screen and (max-width: 768px) {
    .card{
        width: 410px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
      position: relative;
      display: flex;
      flex-direction: column;
      left: 0px;
      top: -430px;
    }
    .info-card{
        /* text-align: center; */
          width: 450px;
          height: 100%;
          position: absolute;
          left: 1%;
          top: 300px;
          background-color: #fff;
          box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.25);
      }
      .info-card h3{
        width: 400px;
        height: 102px;
        font-size: 30px;
        font-weight: 500;
        font-family: Anek Devanagari;
        line-height: 51.17px;
    
    }
}